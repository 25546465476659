/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { gql } from '@apollo/client';

export const CREATE_ORGANISATION = gql`
  mutation createOrganisation($data: OrganisationCreateInput!) {
    createOrganisation(data: $data) {
      _id
      name
      slug
      emailDomain
      workDocsProperties {
        workDocsOrganisationId
        rootFolderId
      }
      slug
      description
      status
      type
    }
  }
`;

export const UPDATE_ORGANISATION = gql`
  mutation updateOrganisation($data: OrganisationUpdateInput!) {
    updateOrganisation(data: $data) {
      _id
      name
      slug
      emailDomain
      workDocsProperties {
        workDocsOrganisationId
        rootFolderId
      }
      slug
      description
      status
      type
    }
  }
`;

export const ADD_USER_ORGANISATION = gql`
  mutation addUserToOrganisation($userId: String!, $organisationId: String!) {
    addUserToOrganisation(userId: $userId, organisationId: $organisationId) {
      user
      firstName
      lastName
      email
      roles
    }
  }
`;

export const UPDATE_INTEGRATION = gql`
  mutation updateIntegrations($orgId: String!, $integration: EmThirdPartyAppIntegrationInputType!) {
    updateIntegrations(orgId: $orgId, integration: $integration) {
      _id
      integrations {
        app
        status
      }
    }
  }
`;
