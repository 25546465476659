/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { gql } from '@apollo/client';

export const GET_INVITED_LIST = gql`
  query invitedSupplierList($data: SupplierListInput!) {
    invitedSupplierList(data: $data) {
      total
      hasMore
      items {
        _id
        firstname
        lastname
        companyname
        email
        type
        isActive
      }
    }
  }
`;
