/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { Grid } from '@mui/material';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormTemplate, IApolloClient } from '@vamstar/fox-frontend-common';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import { IReport } from '@vamstar/fox-api-common/esm/reports';
import { withSnackbar, ProviderContext } from 'notistack';
import { WithTranslation, withTranslation } from 'react-i18next';
import { log } from '@vamstar/fox-node-logger';
import { IEmS3File } from '@vamstar/fox-api-common/esm/file-upload';

import ReportTemplate from '../component/ReportTemplate';
import { fetchData, getSlug } from '../utils';
import { CREATE_REPORT, UPDATE_REPORT } from '../mutations';
import { isSuperAdmin } from '../../../common/utils';
import { GET_SIGNED_URL_FOR_REPORT, GET_SIGNED_URL_FOR_REPORT_THUMBNAIL } from '../queries';
import { uploadFile } from '../../../common/aws-utils';
import { REPORT_DATA } from '../constants';

type HOCProps = WithApolloClient<IApolloClient> & ProviderContext & WithTranslation & RouteComponentProps<MatchParams>;

type ReportFileType = 'reportFile' | 'reportThumbnail';

interface MatchParams {
  _id: string;
}

interface IReportState {
  reportData: IReport;
  isLoading: boolean;
}

class ReportFormView extends React.Component<HOCProps> {
  state: IReportState = {
    reportData: REPORT_DATA,
    isLoading: false,
  };

  async componentDidMount() {
    const {
      client,
      match: {
        params: { _id },
      },
    } = this.props;
    this.setState({ isLoading: true });

    const report = await fetchData(client, _id);

    if (report) {
      this.setState({ reportData: report });
    }
    this.setState({ isLoading: false });
  }

  public getSignedUrl = async (file: File, slug: string, type: ReportFileType) => {
    const { client } = this.props;

    if (file) {
      try {
        const response = await client.query({
          query: type === 'reportFile' ? GET_SIGNED_URL_FOR_REPORT : GET_SIGNED_URL_FOR_REPORT_THUMBNAIL,
          variables: {
            fileName: file.name,
            fileType: file.type,
            slug,
          },
        });
        const signedUrl =
          type === 'reportFile'
            ? response.data.getSignedUrlForReport.signedUrl
            : response.data.getSignedUrlForReportThumbnail.signedUrl;
        await uploadFile(signedUrl, file);
      } catch (error) {
        log.error(JSON.stringify(error));
        throw Error(JSON.stringify(error));
      }
    }
  };

  public uploadFiles = async (files: File[], slug: string, reportFileType: ReportFileType) => {
    await Promise.all(
      files.map((file: File) => {
        return this.getSignedUrl(file, slug, reportFileType);
      }),
    );
  };

  getReportData = async (rowData: IReport) => {
    const { s3File, title, subTitle, description, status, thumbnail, type } = rowData;

    const slug = getSlug(title);
    const thumbnailData = (thumbnail as unknown) as FileList;
    try {
      await this.uploadFiles(Array.from((s3File as unknown) as FileList), slug, 'reportFile');
      await this.uploadFiles(Array.from(thumbnailData), slug, 'reportThumbnail');

      const newS3FileData: IEmS3File = {
        key: slug,
        originalUrl: `${slug}/index.html`,
        bucket: `${process.env.REACT_APP_REPORTS_BUCKET_URL}`,
      };

      const newThumbnailData: IEmS3File = {
        key: slug,
        originalUrl: `${slug}/${thumbnailData[0].name}`,
        bucket: `${process.env.REACT_APP_REPORTS_THUMBNAIL_BUCKET}`,
      };

      return {
        title,
        subTitle,
        description,
        status,
        s3File: newS3FileData,
        thumbnail: newThumbnailData,
        type,
      };
    } catch (err) {
      throw Error(JSON.stringify(err));
    }
  };

  onSave = async (): Promise<void> => {
    const { reportData } = this.state;
    const { client, t, enqueueSnackbar } = this.props;

    if (!reportData) return;

    const modifiedReportData = await this.getReportData(reportData);

    try {
      const reportsResponse = await client.mutate({
        mutation: CREATE_REPORT,
        variables: {
          data: modifiedReportData,
        },
      });

      const { _id } = reportsResponse.data.createReport;

      this.setState({
        reportData: {
          ...reportData,
          _id,
        },
      });
      this.props.history.push({
        pathname: `/report/${_id}`,
      });
      enqueueSnackbar(t('recordCreated'), { variant: 'success' });
    } catch (error) {
      log.error(JSON.stringify(error));
    }
  };

  onUpdate = async () => {
    const {
      reportData: { title, subTitle, description, status, type },
    } = this.state;
    const { _id } = this.props.match.params;
    const { client, t, enqueueSnackbar } = this.props;
    try {
      await client.mutate({
        mutation: UPDATE_REPORT,
        variables: {
          data: {
            filter: { id: _id },
            doc: {
              title,
              subTitle,
              description,
              status,
              type,
            },
          },
        },
      });
      enqueueSnackbar(t('recordUpdated'), { variant: 'success' });
    } catch (error) {
      log.error(JSON.stringify(error));
    }
  };

  onTextFieldChange = (prop: string, value: any) => {
    const { reportData } = this.state;
    this.setState({
      reportData: {
        ...reportData,
        [prop]: value,
      },
    });
  };

  onFileChange = (prop: string, value: Record<string, any>) => {
    const { reportData } = this.state;
    this.setState({
      reportData: {
        ...reportData,
        [prop]: value,
      },
    });
  };

  isSaveDisabled = (): boolean => {
    const {
      reportData: { title },
    } = this.state;

    return !title || title === '';
  };

  render() {
    const { isLoading, reportData } = this.state;

    return (
      <FormTemplate
        isLoading={isLoading}
        onSubmit={() => (reportData._id ? this.onUpdate() : this.onSave())}
        isEdit={!!reportData._id}
        formLabel="Report"
        isFormDisabled={isSuperAdmin() ? this.isSaveDisabled() : true}
      >
        <Grid container item direction="column" spacing={4}>
          <ReportTemplate
            reportData={reportData}
            onTextFieldChange={this.onTextFieldChange}
            onFileChange={this.onFileChange}
          />
        </Grid>
      </FormTemplate>
    );
  }
}

const ReportForm: React.ComponentClass<any, IReportState> = compose<HOCProps, any>(
  withApollo,
  withRouter,
  withSnackbar,
  withTranslation('common'),
)(ReportFormView);

export default ReportForm;
