/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { gql } from '@apollo/client';

import { EmEntitlementFragment } from '../entitlement-template/fragments';

export const LICENCE = gql`
  query licences($data: LicenceListInput!) {
    licences(data: $data) {
      items {
        _id
        licenceTemplate
        name
        startDate
        endDate
        type
        rights {
          ...EmEntitlementFields
        }
        pricePerSeat {
          currency
          value
        }
        basePrice {
          currency
          value
        }
        user {
          user
          firstName
          lastName
          email
        }
        organisation {
          _id
          name
          slug
          emailDomain
        }
        seatsIncluded
        numberOfSeats
        status
      }
      total
      hasMore
    }
  }
  ${EmEntitlementFragment.emEntitlementFields}
`;

export const FIND_LICENCE_BY_ID = gql`
  query findLicence($type: LicenceType!, $id: String!) {
    findLicence(type: $type, id: $id) {
      _id
      licenceTemplate
      name
      startDate
      endDate
      type
      rights {
        ...EmEntitlementFields
      }
      pricePerSeat {
        currency
        value
      }
      basePrice {
        currency
        value
      }
      user {
        user
        firstName
        lastName
        email
        roles
      }
      organisation {
        _id
        name
        slug
        emailDomain
      }
      seatsIncluded
      numberOfSeats
      status
    }
  }
  ${EmEntitlementFragment.emEntitlementFields}
`;
