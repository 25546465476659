/**
 * Copyright © 2019 - Present, VamStar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
const omitDeep = require('omit-deep-lodash');

export const removeProp = (record: Record<string, any> | undefined, propToDelete: string) => {
  return omitDeep(record, propToDelete);
};

export const deepOmitPropArray = (records: any[] | undefined, propToDelete: string) => {
  if (records) {
    return omitDeep(records, propToDelete);
  }
  return [];
};
