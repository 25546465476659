/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import { VAMSTAR_LOGO } from './constants';

const style = makeStyles(() => ({
  container: {
    height: '100vh',
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  logo: {
    alignSelf: 'center',
  },
}));

export const Logo = () => {
  const classes = style();
  return (
    <div className={classes.logo}>
      <img alt="Vamstar logo" src={VAMSTAR_LOGO} />
    </div>
  );
};
