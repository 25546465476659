/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser($data: UserCreateInput!) {
    createUser(data: $data) {
      _id
      basicInfo {
        email
        firstName
        lastName
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($data: UserUpdateInputType!) {
    updateUser(data: $data) {
      _id
      basicInfo {
        email
        firstName
        lastName
        roles
      }
    }
  }
`;

export const UPDATE_USER_ORGANISATION = gql`
  mutation updateUserToOrganisation($userId: String!) {
    updateUserToOrganisation(userId: $userId) {
      user
      firstName
      lastName
      email
      roles
    }
  }
`;
