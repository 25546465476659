/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { gql } from '@apollo/client';

import { ReportFragment } from './fragments';

export const CREATE_REPORT = gql`
  mutation createReport($data: ReportCreateInput!) {
    createReport(data: $data) {
      ...ReportFields
    }
  }
  ${ReportFragment.reportFields}
`;

export const UPDATE_REPORT = gql`
  mutation updateReport($data: ReportUpdateInput!) {
    updateReport(data: $data) {
      ...ReportFields
    }
  }
  ${ReportFragment.reportFields}
`;
