/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export const VAMSTAR_LOGO =
  'https://prd-vamstar-public-hosted-images.s3-eu-west-1.amazonaws.com/vamstar-horizontal.png';
