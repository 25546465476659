/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React from 'react';
import { Paper, Grid, Typography, useTheme } from '@mui/material';
import CountUp from 'react-countup';

import { useActivityCardStyles } from '../styles';

interface IActivityCardProps {
  icon: React.ReactElement;
  activityCount: number;
  activityName: string;
}

export const ActivityCard = (props: IActivityCardProps) => {
  const { activityCount, activityName, icon } = props;
  const classes = useActivityCardStyles();
  const theme = useTheme();

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container className={classes.grid} style={{ paddingTop: theme.spacing(4) }}>
          <Grid item>{icon}</Grid>
        </Grid>
        <Grid container className={classes.grid}>
          <Grid item>
            <Typography variant="h2" style={{ fontWeight: 'bold' }}>
              <CountUp end={activityCount} duration={3} />
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.grid}>
          <Grid item>
            <Typography variant="caption" display="block" style={{ fontWeight: 'lighter' }} gutterBottom>
              Total {activityName}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
