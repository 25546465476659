/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import createStyles from '@mui/styles/createStyles';

export const entitlementTagModalStyles = createStyles({
  textField: {
    marginTop: '15px',
  },
  chip: {
    margin: '5px',
  },
});
