/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import * as React from 'react';
import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client';

import { CommonPaginationService } from './common-pagination.service';

const commonPaginationService: CommonPaginationService = new CommonPaginationService();

export interface WithCommonPagination {
  commonPaginationService: CommonPaginationService;
}

/**
 * Functional component hook for getting the queries service instance
 */
export const useCommonPagination = () => {
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  commonPaginationService.apolloClient = client;
  return commonPaginationService;
};

/**
 * withCommonPaginationService HOC Wrapper for class based components
 * @param Component
 */
export const withCommonPagination = <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P & WithCommonPagination> => (props: WithCommonPagination) => {
  const commonPaginationServiceProp = useCommonPagination();
  const newProps = { ...props, commonPaginationService: commonPaginationServiceProp };
  return <Component {...(newProps as P)} />;
};
