import React from 'react';
import { Grid } from '@mui/material';
import { ILicence, LicenceType } from '@vamstar/fox-api-common/esm/licence/types';

import { BasePrice, PricePerSeat } from '../../../common/components/BasePriceAndPricePerSeat';

interface IBasePriceRendererProps {
  licenceData: Partial<ILicence>;
  onChange: (prop: string, value: any) => void;
}

export const BasePriceAndPricePerSeatRenderer = (props: IBasePriceRendererProps) => {
  const {
    licenceData,
    licenceData: { type },
    onChange,
  } = props;

  const handleOnChange = (prop: string, value: any) => {
    if (type === LicenceType.USER && (prop === 'seatsIncluded' || prop === 'numberOfSeats')) {
      onChange('numberOfSeats', 1);
      onChange('seatsIncluded', 1);
    } else {
      onChange(prop, value);
    }
  };

  return (
    <Grid item container direction="row" justifyContent="space-between">
      <Grid item>
        <BasePrice data={licenceData} onChange={handleOnChange} />
      </Grid>
      <Grid item>
        <PricePerSeat data={licenceData} onChange={handleOnChange} />
      </Grid>
    </Grid>
  );
};
