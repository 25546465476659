/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { Login as CommonLogin, IApolloClient } from '@vamstar/fox-frontend-common';
import React, { Component, ComponentType } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { ILoginFormData } from '@vamstar/fox-frontend-common/dist/main/auth/Login';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import { log } from '@vamstar/fox-node-logger';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';

import { LOGIN } from './mutations';
import { setUserData, getAuthData } from '../../util/storage';
import { Role } from './constants';
import { authStyles } from './styles';
import { Logo } from './Logo';

interface ILoginState {
  isLoading?: boolean;
}

type HOCProps = WithTranslation & RouteComponentProps & WithApolloClient<IApolloClient> & WithStyles<any>;

class LoginView extends Component<HOCProps, ILoginState> {
  public state: ILoginState = {
    isLoading: false,
  };

  public login = async (formData: ILoginFormData) => {
    const { client, history } = this.props;
    this.setState({ isLoading: true });

    try {
      const { data } = await client.mutate({
        mutation: LOGIN,
        variables: { ...formData, role: Role.ADMIN },
        fetchPolicy: 'no-cache',
      });
      setUserData(data.login);

      history.push('/system-activity');
    } catch (error) {
      log.error(`${error}`);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  public render() {
    const { isLoading } = this.state;
    const { t, history, classes } = this.props;
    const authData = getAuthData();

    if (authData) {
      return <Redirect to="/system-activity" />;
    }

    return (
      <div className={classes.container}>
        <Logo />
        <div>
          <CommonLogin
            isLoading={isLoading}
            signinLabel={t('Login')}
            emailLabel={t('labels:email')}
            forgotPasswordLabel={t('labels:forgotPassword')}
            passwordLabel={t('labels:password')}
            rememberMeLabel={t('labels:rememberMe')}
            onForgotPasswordClick={() => {
              history.push('reset-password');
            }}
            onLogin={this.login}
            xs={5}
          />
        </div>
      </div>
    );
  }
}

export const Login = compose(
  withTranslation(),
  withApollo,
  withStyles(authStyles),
)(LoginView as ComponentType<unknown>);
