/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { gql } from '@apollo/client';

import { ReportFragment } from './fragments';

export const GET_SIGNED_URL_FOR_REPORT = gql`
  query getSignedUrlForReport($fileName: String!, $fileType: String!, $slug: String!) {
    getSignedUrlForReport(fileName: $fileName, fileType: $fileType, slug: $slug) {
      signedUrl
      fileName
    }
  }
`;

export const GET_SIGNED_URL_FOR_REPORT_THUMBNAIL = gql`
  query getSignedUrlForReportThumbnail($fileName: String!, $fileType: String!, $slug: String!) {
    getSignedUrlForReportThumbnail(fileName: $fileName, fileType: $fileType, slug: $slug) {
      signedUrl
      fileName
    }
  }
`;

export const REPORT_BY_ID = gql`
  query report($id: String!) {
    report(id: $id) {
      ...ReportFields
    }
  }
  ${ReportFragment.reportFields}
`;

export const REPORTS = gql`
  query reports($data: ReportListInput!) {
    reports(data: $data) {
      items {
        ...ReportFields
      }
      total
      hasMore
    }
  }
  ${ReportFragment.reportFields}
`;
