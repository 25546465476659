/**
 * Copyright © 2019 - Present, VamStar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import * as React from 'react';
import { Grid, TextField, Box, IconButton } from '@mui/material';
import Search from '@mui/icons-material/Search';
import { pxToRem } from '@vamstar/fox-frontend-common';

const DEFAULT_SEARCH_NAME = 'Search';

interface ITableSearchProps {
  value: string;
  onSearchChange: (searchText: string) => void;
  onSearchSubmit: () => void;
}

export const XGridSearch = (props: ITableSearchProps) => {
  const { onSearchSubmit, onSearchChange, value } = props;

  return (
    <Grid container justifyContent="flex-end">
      <Box display="flex">
        <Box>
          <TextField
            value={value}
            size="small"
            sx={{
              '& .MuiInputBase-root': {
                borderTopRightRadius: pxToRem('0px'),
                borderBottomRightRadius: pxToRem('0px'),
                paddingRight: pxToRem('0px'),
              },
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (onSearchChange) onSearchChange(event.target.value);
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (onSearchSubmit && event.key === 'Enter') onSearchSubmit();
            }}
            label={DEFAULT_SEARCH_NAME}
            InputProps={{
              endAdornment: (
                <Box
                  onClick={() => onSearchSubmit()}
                  sx={{
                    height: pxToRem('37px'),
                    background: '#3366ff',
                    borderTopRightRadius: pxToRem('4px'),
                    borderBottomRightRadius: pxToRem('4px'),
                    width: '20%',
                  }}
                >
                  <IconButton edge="end" color="primary">
                    <Search style={{ fill: 'white' }} />
                  </IconButton>
                </Box>
              ),
            }}
          />
        </Box>
      </Box>
    </Grid>
  );
};
