import { Styles } from '@mui/styles';

export const authStyles = {
  container: {
    height: '100vh',
    width: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  logo: {
    alignSelf: 'center',
  },
} as Styles<any, any>;
