/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { DEFAULT_PAGE_SIZE, IApolloClient, XGridTable } from '@vamstar/fox-frontend-common';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { log } from '@vamstar/fox-node-logger';
import { IClaimSupplierProfileToken } from '@vamstar/fox-api-common/esm/claim-supplier-profile/types';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';

import { columns } from './columns';
import { XGridTableWrapper } from '../../common/components/XGridTableWrapper';
import { WithCommonPagination, withCommonPagination } from '../../common/utils/WithCommonPagination';
import { GET_INVITED_LIST } from './queries';

interface IMailInviteTableState {
  invitedEntities: IClaimSupplierProfileToken[];
  isLoading: boolean;
  totalCount: number;
  currentPage: number;
  pageSize: number;
}

type HOCProps = WithCommonPagination & WithApolloClient<IApolloClient>;

class MailInviteTableView extends Component<HOCProps, IMailInviteTableState> {
  state: IMailInviteTableState = {
    invitedEntities: [],
    isLoading: false,
    currentPage: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ isLoading: true });

    try {
      const { client } = this.props;
      const { currentPage, pageSize } = this.state;
      const skip = (currentPage - 1) * pageSize;

      const invitedListResponse = await client.query({
        query: GET_INVITED_LIST,
        variables: {
          data: {
            skip,
            take: pageSize,
          },
        },
        fetchPolicy: 'network-only',
      });

      if (invitedListResponse) {
        const invitedSuppliers = invitedListResponse.data.invitedSupplierList || {};
        if (invitedSuppliers) {
          const list = invitedSuppliers.items || [];
          const totalCount = invitedSuppliers.total;
          this.setState({ invitedEntities: list, totalCount });
        }
      }
    } catch (error) {
      log.error(JSON.stringify(error));
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onPageSizeChange = (newPageSize: number) => {
    this.setState({ pageSize: newPageSize, currentPage: 1 }, () => this.fetchData());
  };

  onPageChange = (page: number) => {
    this.setState({ currentPage: page }, () => this.fetchData());
  };

  render() {
    const { isLoading, invitedEntities, totalCount, pageSize, currentPage } = this.state;
    return (
      <XGridTableWrapper heading="Invite Suppliers" route="/invite-supplier" buttonLabel="Send Invites">
        <XGridTable
          disableSelectionOnClick
          disableColumnFilter
          columns={columns}
          disableColumnResize
          rows={invitedEntities}
          autoHeight
          count={totalCount}
          loading={isLoading}
          onPageChange={this.onPageChange}
          onPageSizeChange={this.onPageSizeChange}
          page={currentPage}
          rowsPerPage={pageSize}
        />
      </XGridTableWrapper>
    );
  }
}

const InvitedSuppliersTable: React.ComponentClass<any, IMailInviteTableState> = compose<HOCProps, any>(
  withCommonPagination,
  withApollo,
)(MailInviteTableView);

export default InvitedSuppliersTable;
