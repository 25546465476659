/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { VamProduct } from '@vamstar/fox-api-common/esm/vam-product/types/vam-product.enum';

export const DEFAULT_PRODUCT_TYPE = VamProduct.ADMIN_DASHBOARD;
