/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Dashboard } from '../features/dashboard/Dashboard';
import { dashboardRoutes } from '../features/dashboard/routes';
import { NotFound } from './NotFound';
import { PrivateRoute } from '../common/components/PrivateRoute';
import { ICustomRouteProps } from './interfaces';

export const DashboardNavigator = () => (
  <Dashboard>
    <Switch>
      {dashboardRoutes.map((routeConfig: ICustomRouteProps) => {
        if (routeConfig.isPrivate) {
          return (
            <PrivateRoute
              exact
              path={routeConfig.path}
              key={routeConfig.title}
              component={(routeConfig.component as unknown) as React.ComponentClass}
            />
          );
        }
        return <Route key={routeConfig.path as string} {...routeConfig} />;
      })}
      <NotFound />
    </Switch>
  </Dashboard>
);
