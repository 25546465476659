/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';

import { isSuperAdmin } from '../../common/utils';
import { EditButton, ViewButton } from '../../common/components/LinkButton';

export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => (
      <>
        {params.row.basePrice.currency} {params.row.basePrice.value}
      </>
    ),
    field: 'basePrice.value',
    headerName: 'Base Price',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => (
      <>
        {params.row.pricePerSeat.currency} {params.row.pricePerSeat.value}
      </>
    ),
    field: 'pricePerSeat.value',
    headerName: 'Price/Seat',
    type: 'string',
    flex: 1,
  },
  {
    field: 'seatsIncluded',
    headerName: 'Seats Included',
    type: 'string',
    flex: 1,
  },
  { field: 'numberOfSeats', headerName: 'Number of Seats', type: 'string', flex: 1 },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    flex: 1,
  },
  {
    field: 'edit',
    headerName: 'Actions',
    renderCell: (params: GridCellParams) => {
      if (isSuperAdmin()) {
        return <EditButton path={`/licence-template/${params.row.id}`} title="Edit Licence Template" />;
      }
      return <ViewButton path={`/licence-template/${params.row.id}`} title="View Licence Template" />;
    },
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
];
