/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { gql } from '@apollo/client';

export const SEND_MAIL_INVITES = gql`
  mutation inviteSuppliers($items: [ClaimSupplierProfileInput!]!) {
    inviteSuppliers(items: $items) {
      inviteSentList
      inviteNotSentList
    }
  }
`;
