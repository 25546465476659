/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React, { Component, ComponentType } from 'react';
import clsx from 'clsx';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Copyright, EnvironmentBand } from '@vamstar/fox-frontend-common';
import { compose } from 'recompose';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { logo } from '../../constants/images';
import { Drawer } from './Drawer';
import { styles } from './styles';
import { Logout } from '../auth/Logout';

type DashboardProps = WithStyles<any> & RouteComponentProps<any> & WithTranslation;

const environment = process.env.REACT_APP_STAGE || process.env.STAGE || '';
const version = process.env.REACT_APP_VERSION || '';

class DashboardView extends Component<DashboardProps> {
  public state = {
    isDrawerOpen: false,
  };

  public closeDrawer = () => {
    this.setState({
      isDrawerOpen: false,
    });
  };

  public openDrawer = () => {
    this.setState({
      isDrawerOpen: true,
    });
  };

  public render() {
    const { isDrawerOpen } = this.state;
    const { classes, children, t } = this.props;

    return (
      <div className={classes.root}>
        <EnvironmentBand environment={environment} version={version} />
        <AppBar position="absolute" className={clsx(classes.appBar, isDrawerOpen && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={this.openDrawer}
              className={clsx(classes.menuButton, isDrawerOpen && classes.menuButtonHidden)}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <Grid container alignItems="center" direction="row">
              <img alt="avatar" style={{ width: 40, borderRadius: 50, border: '1px solid whitesmoke' }} src={logo} />
              <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                Vamstar Admin
              </Typography>
            </Grid>
            <Logout />
          </Toolbar>
        </AppBar>
        <Drawer closeDrawer={this.closeDrawer} isDrawerOpen={isDrawerOpen} />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
            <div>{children}</div>
          </Container>
          <Copyright text={t('copyright')} />
        </main>
      </div>
    );
  }
}

export const Dashboard = compose(
  withStyles(styles),
  withRouter,
  withTranslation('common'),
)(DashboardView as ComponentType<unknown>);
