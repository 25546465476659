/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { gql } from '@apollo/client';

export const GET_SYSTEM_ACTIVITIES = gql`
  query getSystemActivities($type: [SystemActivityRefPath!]!, $startDate: Date, $endDate: Date) {
    getSystemActivities(type: $type, startDate: $startDate, endDate: $endDate) {
      id
      data {
        x
        y
      }
    }
  }
`;

export const GET_ACTIVITY_COUNT = gql`
  query getActivityCount($userData: UserListInput!, $savedSearchesData: SavedSearchListInput!) {
    users(data: $userData) {
      total
    }
    savedsearchs(data: $savedSearchesData) {
      total
    }
  }
`;
