/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { Chip } from '@mui/material';

import { getInvitationStatus } from './invite-suppliers-utils';

export const columns: GridColDef[] = [
  {
    field: 'companyname',
    headerName: 'Company',
    type: 'string',
    flex: 1,
  },
  {
    field: 'fullname',
    headerName: 'User',
    type: 'string',
    flex: 1,
    renderCell: (params: GridCellParams) => (
      <>
        {params.row.firstname} {params.row.lastname}
      </>
    ),
  },
  {
    field: 'email',
    headerName: 'Email',
    type: 'string',
    flex: 1,
    renderCell: (params: GridCellParams) => <>{params.row.email}</>,
  },
  {
    field: 'type',
    headerName: 'Invite Status',
    type: 'string',
    align: 'center',
    flex: 1,
    renderCell: (params: GridCellParams) => (
      <Chip style={{ backgroundColor: '#CED0D7' }} label={getInvitationStatus(params.row)} />
    ),
  },
];
