/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, FormHelperText, TextField, SelectChangeEvent } from '@mui/material';

import { MenuProps } from '../styles';

export interface ISelectFieldProps {
  data: string[];
  onChange: (newValue: string) => void;
  label: string;
  defaultValue?: string;
  hasError: boolean;
  required: boolean;
  helperText?: string;
  disabled?: boolean;
}

export const SingleSelectField = (props: ISelectFieldProps) => {
  const { data, onChange, label, defaultValue, hasError, required, helperText, disabled } = props;

  const [selectedValue, setSelectedValue] = React.useState<string>(defaultValue || '');

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <>
      {data.length > 0 ? (
        <FormControl
          variant="filled"
          fullWidth
          margin="normal"
          error={hasError}
          required={required}
          disabled={disabled}
        >
          <InputLabel id={label}>{label}</InputLabel>
          <Select value={selectedValue} onChange={handleChange} fullWidth MenuProps={MenuProps}>
            {data.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      ) : (
        <TextField fullWidth margin="normal" variant="filled" value={selectedValue} label={label} disabled />
      )}
    </>
  );
};
