import { ObjectId } from 'bson';
import { ApolloClient } from '@apollo/client';
import { EntitlementStatus } from '@vamstar/fox-api-common/esm/entitlement-template/types';

import { LICENCE_TEMPLATE_BY_ID } from './queries';
import { ENTITLEMENT } from '../entitlement-template/queries';

export const fetchLicenceTemplateById = async (client: ApolloClient<any>, id: string | ObjectId) => {
  const response = await client.query({
    query: LICENCE_TEMPLATE_BY_ID,
    variables: {
      id,
    },
  });

  return response.data.licencetemplate;
};

export const fetchEntitlementData = async (client: ApolloClient<any>) => {
  const response = await client.query({
    query: ENTITLEMENT,
    variables: {
      data: {
        skip: 0,
        take: 100,
        filter: {
          status: EntitlementStatus.ACTIVE,
        },
      },
    },
  });

  return response.data.entitlementtemplates.items;
};
