/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
const ITEM_HEIGHT = 48;
const ITEM_MULTIPLIER = 4.5;
const ITEM_PADDING_TOP = 8;

// Select field Menu props
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * ITEM_MULTIPLIER + ITEM_PADDING_TOP,
    },
  },
};
