/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import * as React from 'react';
import { DateUtils } from '@vamstar/fox-api-common/esm/utils';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker } from '@vamstar/fox-frontend-common';

interface ILicenceDataPickerProps {
  onChange: (newValue: any) => void;
  currentDate: string;
  id: string;
  title: string;
  minDate?: string;
}

export const LicenceDatePicker = (props: ILicenceDataPickerProps) => {
  const { onChange, id, currentDate, title, minDate } = props;
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(currentDate ? new Date(currentDate) : new Date());

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    onChange(DateUtils.toISOString(date));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker<Date>
        required
        minDate={minDate ? new Date(minDate) : undefined}
        margin="normal"
        id={id}
        label={title}
        inputFormat="dd/MM/yyyy"
        value={selectedDate}
        onChange={handleDateChange}
      />
    </LocalizationProvider>
  );
};
