/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import { FormControl, Grid, InputLabel, TextField } from '@mui/material';
import React from 'react';
import { ObjectId } from 'bson';
import { RouteComponentProps, withRouter } from 'react-router';
import { IApolloClient, FormTemplate } from '@vamstar/fox-frontend-common';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { compose } from 'recompose';
import { withSnackbar, ProviderContext } from 'notistack';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ILicenceTemplate, LicenceTemplateStatus } from '@vamstar/fox-api-common/esm/licence-template/types';
import { IEntitlementTemplate } from '@vamstar/fox-api-common/esm/entitlement-template/types';
import { log } from '@vamstar/fox-node-logger';

import { MultiSelectField } from '../../../common/components/MultiSelectField';
import { CREATE_LICENCE_TEMPLATE, UPDATE_LICENCE_TEMPLATE } from '../mutations';
import { deepOmitPropArray } from '../../../util/object';
import { isSuperAdmin } from '../../../common/utils';
import { NumberOfSeatsRenderer, BasePriceAndPricePerSeatRenderer } from '../components/renderers';
import { CURRENCY } from '../../../constants';
import { SingleSelectField } from '../../../common/components/SelectField';
import { fetchEntitlementData, fetchLicenceTemplateById } from '../utils';

interface MatchParams {
  _id: string;
}

interface ILicenceTemplateState {
  isLoading: boolean;
  licenceTemplateData: Partial<ILicenceTemplate>;
  entitlementData: IEntitlementTemplate[];
}

type HOCProps = WithApolloClient<IApolloClient> & ProviderContext & WithTranslation & RouteComponentProps<MatchParams>;

class LicenceTemplateView extends React.Component<HOCProps> {
  public state: ILicenceTemplateState = {
    isLoading: false,
    licenceTemplateData: {
      _id: this.props.match.params._id,
      basePrice: {
        currency: CURRENCY.GBP,
        value: undefined,
      },
      pricePerSeat: {
        currency: CURRENCY.GBP,
        value: undefined,
      },
      seatsIncluded: 1,
      numberOfSeats: 1,
      status: LicenceTemplateStatus.ACTIVE,
    },
    entitlementData: [],
  };

  async componentDidMount() {
    const {
      licenceTemplateData: { _id },
    } = this.state;
    this.setState({ isLoading: true });

    if (_id) {
      await this.getLicenceTemplateById(_id);
    } else {
      await this.fetchEntitlementTemplateData();
    }
    this.setState({ isLoading: false });
  }

  onSave = async () => {
    const { client, enqueueSnackbar, t } = this.props;
    const {
      licenceTemplateData,
      licenceTemplateData: { rights, basePrice, name, status, pricePerSeat, seatsIncluded, numberOfSeats },
    } = this.state;

    const newRights = deepOmitPropArray(rights, '__typename');
    try {
      const response = await client.mutate({
        mutation: CREATE_LICENCE_TEMPLATE,
        variables: {
          data: { rights: newRights, basePrice, name, status, pricePerSeat, seatsIncluded, numberOfSeats },
        },
      });

      const { _id } = response.data.createLicencetemplate;
      const newLicenceTemplateData: Partial<ILicenceTemplate> = {
        ...licenceTemplateData,
        _id,
      };

      this.setState({ licenceTemplateData: newLicenceTemplateData });
      this.props.history.push({
        pathname: `/licence-template/${_id}`,
      });

      enqueueSnackbar(t('recordCreated'), { variant: 'success' });
    } catch (error) {
      log.error(JSON.stringify(error));
    }
  };

  onUpdate = async () => {
    const { client, enqueueSnackbar, t } = this.props;
    const {
      licenceTemplateData: { _id, status },
    } = this.state;

    try {
      await client.mutate({
        mutation: UPDATE_LICENCE_TEMPLATE,
        variables: {
          data: {
            filter: { id: _id },
            doc: { status },
          },
        },
      });

      enqueueSnackbar(t('recordUpdated'), { variant: 'success' });
    } catch (error) {
      log.error(JSON.stringify(error));
    }
  };

  getLicenceTemplateById = async (id: string | ObjectId) => {
    const { client } = this.props;
    const licenceTemplateData = await fetchLicenceTemplateById(client, id);

    this.setState({ licenceTemplateData, entitlementData: licenceTemplateData.rights });
  };

  fetchEntitlementTemplateData = async () => {
    const { client } = this.props;
    try {
      const entitlementData = await fetchEntitlementData(client);

      this.setState({ entitlementData });
    } catch (error) {
      log.error(JSON.stringify(error));
    }
  };

  isSaveDisabled = (): boolean => {
    const {
      licenceTemplateData: { name, basePrice, pricePerSeat },
    } = this.state;

    return !name || name === '' || !basePrice || !basePrice.value || !pricePerSeat || !pricePerSeat.value;
  };

  onLicenceTemplatePropsChange = (prop: string, value: any) => {
    this.setState((prevState: ILicenceTemplateState) => ({
      licenceTemplateData: {
        ...prevState.licenceTemplateData,
        [prop]: value,
      },
    }));
  };

  renderSeatsIncluded = () => {
    const {
      licenceTemplateData: { seatsIncluded },
    } = this.state;

    return (
      <Grid item>
        <TextField disabled fullWidth required variant="filled" value={seatsIncluded} label="Seats Included" />
      </Grid>
    );
  };

  renderName = () => {
    const {
      licenceTemplateData,
      licenceTemplateData: { _id, name },
    } = this.state;
    return (
      <Grid item>
        <TextField
          disabled={_id !== undefined}
          fullWidth
          required
          variant="filled"
          value={name || ''}
          error={!name || name === ''}
          label="Name"
          onChange={(e) => {
            this.setState({
              licenceTemplateData: {
                ...licenceTemplateData,
                name: e.target.value,
              },
            });
          }}
        />
      </Grid>
    );
  };

  renderRights = () => {
    const {
      licenceTemplateData,
      licenceTemplateData: { _id, rights },
      entitlementData,
    } = this.state;
    return (
      <Grid item>
        <FormControl variant="filled" fullWidth margin="normal" disabled={_id !== undefined}>
          <InputLabel>Rights</InputLabel>

          <MultiSelectField
            data={entitlementData}
            value={rights}
            onChange={(data) => {
              licenceTemplateData.rights = data;
              this.setState({ licenceTemplateData });
            }}
          />
        </FormControl>
      </Grid>
    );
  };

  renderStatus = () => {
    const {
      licenceTemplateData,
      licenceTemplateData: { status },
    } = this.state;

    return (
      <Grid item>
        <SingleSelectField
          data={[LicenceTemplateStatus.ACTIVE, LicenceTemplateStatus.INACTIVE]}
          label="Status"
          onChange={(value) =>
            this.setState({
              licenceTemplateData: {
                ...licenceTemplateData,
                status: value as LicenceTemplateStatus,
              },
            })
          }
          defaultValue={status}
          required={false}
          hasError={false}
        />
      </Grid>
    );
  };

  render() {
    const {
      isLoading,
      licenceTemplateData,
      licenceTemplateData: { _id },
    } = this.state;

    return (
      <FormTemplate
        isLoading={isLoading}
        onSubmit={() => (_id ? this.onUpdate() : this.onSave())}
        isEdit={!!_id}
        formLabel="Licence Template"
        isFormDisabled={isSuperAdmin() ? this.isSaveDisabled() : true}
      >
        <Grid container item direction="column" spacing={4}>
          {this.renderName()}
          {this.renderRights()}
          <Grid item container direction="row" justifyContent="space-between">
            <BasePriceAndPricePerSeatRenderer
              licenceTemplateData={licenceTemplateData}
              onChange={this.onLicenceTemplatePropsChange}
            />
          </Grid>
          <Grid item container direction="row" justifyContent="space-between">
            <NumberOfSeatsRenderer
              licenceTemplateData={licenceTemplateData}
              onChange={this.onLicenceTemplatePropsChange}
            />
            {this.renderSeatsIncluded()}
          </Grid>
          {this.renderStatus()}
        </Grid>
      </FormTemplate>
    );
  }
}

const LicenceTemplate: React.ComponentClass<any, ILicenceTemplateState> = compose<HOCProps, any>(
  withApollo,
  withRouter,
  withSnackbar,
  withTranslation('common'),
)(LicenceTemplateView);

export default LicenceTemplate;
