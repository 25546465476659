import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface IButtonProps {
  path: string;
  title: string;
}

export const EditButton = (props: IButtonProps) => {
  const { path, title } = props;
  return (
    <LinkButton path={path} title={title}>
      <EditIcon />
    </LinkButton>
  );
};

export const ViewButton = (props: IButtonProps) => {
  const { path, title } = props;
  return (
    <LinkButton path={path} title={title}>
      <VisibilityIcon />
    </LinkButton>
  );
};

interface ILinkButtonProps extends IButtonProps {
  children?: React.ReactNode;
}
const LinkButton = (props: ILinkButtonProps) => {
  const { path, title, children } = props;
  return (
    <Link to={path}>
      <Tooltip title={title}>
        <IconButton size="large">{children}</IconButton>
      </Tooltip>
    </Link>
  );
};
