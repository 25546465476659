/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { gql } from '@apollo/client';

export const ORGANISATION = gql`
  query organisations($data: OrganisationListInput!) {
    organisations(data: $data) {
      items {
        _id
        name
        slug
        emailDomain
        slug
        workDocsProperties {
          workDocsOrganisationId
          rootFolderId
        }
        integrations {
          app
          status
        }
        description
        status
        type
      }
      total
      hasMore
    }
  }
`;

export const ORGANISATION_BY_ID = gql`
  query findOrganisation($id: String!) {
    findOrganisation(id: $id) {
      _id
      name
      slug
      emailDomain
      description
      slug
      workDocsProperties {
        workDocsOrganisationId
        rootFolderId
      }
      licence {
        _id
        name
      }
      status
      type
    }
  }
`;

export const ORGANISATION_USERS = gql`
  query getAllUsersInOrganisation($id: String!) {
    getAllUsersInOrganisation(id: $id) {
      user
      firstName
      lastName
      email
      roles
    }
  }
`;

export const GET_ORGANISATION_METRICS = gql`
  query($organisationId: String!) {
    getLicencedTagsCount(organisationId: $organisationId) {
      aggregations
      total
    }
    getOpenTendersCount(organisationId: $organisationId) {
      aggregations
      total
    }
    getDashboardKeywordsCount(organisationId: $organisationId) {
      aggregations
      total
    }
  }
`;
