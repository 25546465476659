/**
 * Copyright © 2019 - Present, VamStar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

export const ORGANISATION_FORM_CONSTRAINTS = {
  name: {
    presence: false,
  },
  description: {
    presence: false,
  },
  emailDomain: {
    presence: false,
    format: {
      pattern: '^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\\.)+[A-Za-z]{2,6}', // Domain regex
    },
  },
  workDocsOrganisationId: {
    presence: false,
  },
  rootFolderId: {
    presence: false,
  },
  status: {
    presence: false,
  },
  type: {
    presence: false,
  },
};
