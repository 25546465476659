/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import React from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
  SelectChangeEvent,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { IEntitlementTag } from '@vamstar/fox-api-common/esm/entitlement-template/types';
import { FilterKey } from '@vamstar/fox-api-common/esm/common/types';
import { withTranslation, WithTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { withSnackbar, ProviderContext } from 'notistack';
import Close from '@mui/icons-material/Close';
import { ObjectId } from 'bson';
import { SUPPORTED_FACET_CONFIG } from '@vamstar/fox-api-common/esm/constants';

import AddEditEntitlementValues from './AddEditEntitlementValues';
import { entitlementTagModalStyles } from './styles';

interface IEntitlementTagModalProps {
  entitlementId?: string | ObjectId;
  onChange: (newValue: any) => void;
  entitledTags: IEntitlementTag[];
  allowEditAlways?: boolean;
}

interface IEntitlementTagModalState {
  open: boolean;
  filterKey: FilterKey | 'none';
  currentEntitlementTag?: IEntitlementTag;
}

type HOCProps = ProviderContext & WithTranslation & IEntitlementTagModalProps & WithStyles<any>;

class EntitlementTagModalView extends React.Component<HOCProps, IEntitlementTagModalState> {
  state: IEntitlementTagModalState = {
    open: false,
    filterKey: 'none',
    currentEntitlementTag: undefined,
  };

  handleModal = () => {
    const { open } = this.state;
    this.setState({ open: !open, filterKey: 'none', currentEntitlementTag: undefined });
  };

  /**
   * filterKey change event to handle select menu changes. Finds if tag is present or sets the current entitlement tag
   * @param event - event produced while changing the dropdown
   * */
  onFilterKeyChange = (event: SelectChangeEvent<FilterKey | 'none'>) => {
    if (event.target.value !== 'none') {
      const { entitledTags } = this.props;
      const foundTag = entitledTags.find((tag) => tag.filterKey === event.target.value);
      this.setState({
        currentEntitlementTag: foundTag || {
          filterKey: event.target.value as FilterKey,
          excludedValues: [],
          allowedValues: [],
        },
        filterKey: event.target.value as FilterKey,
      });
    } else {
      this.setState({ currentEntitlementTag: undefined, filterKey: 'none' });
    }
  };

  /**
   * Checks if value is already part of any of the values. Prevents duplicates
   * @param currentValue - current value user's trying to add
   * */
  checkIfAlreadyPresent = (currentValue: string): boolean => {
    const { currentEntitlementTag } = this.state;
    const { enqueueSnackbar } = this.props;
    if (currentEntitlementTag) {
      const { allowedValues, excludedValues } = currentEntitlementTag;
      if (allowedValues.includes(currentValue) || excludedValues.includes(currentValue)) {
        enqueueSnackbar('Cannot add duplicates. Please try a different name!', { variant: 'warning' });
        return false;
      }
    }
    return true;
  };

  /**
   * adds the value to either of allowed values or excluded values
   * @param currentValue - value user entered
   * @param isAllowedValues - flag to determine whether allowed or exclude values
   * */
  onAddValue = (currentValue: string, isAllowedValues: boolean) => {
    const { currentEntitlementTag } = this.state;
    if (currentEntitlementTag && currentValue && this.checkIfAlreadyPresent(currentValue)) {
      if (isAllowedValues) {
        currentEntitlementTag.allowedValues.push(currentValue);
      }
      if (!isAllowedValues) {
        currentEntitlementTag.excludedValues.push(currentValue);
      }
      this.setState({ currentEntitlementTag });
    }
  };

  /**
   * deletes the value from either of allowed values or excluded values
   * @param index - index of the value to delete
   * @param isAllowedValues - flag to determine whether allowed or exclude values
   * */
  onDeleteValue = (index: number, isAllowedValues: boolean) => {
    const { currentEntitlementTag } = this.state;
    if (currentEntitlementTag) {
      if (isAllowedValues) {
        currentEntitlementTag.allowedValues.splice(index, 1);
      }
      if (!isAllowedValues) {
        currentEntitlementTag.excludedValues.splice(index, 1);
      }
      this.setState({ currentEntitlementTag });
    }
  };

  /**
   * First chacks if tag is already part of users entitled tags else adds the new tag
   * */
  onSaveEntitlementTags = () => {
    const { currentEntitlementTag } = this.state;
    const { entitledTags, onChange } = this.props;
    if (currentEntitlementTag) {
      const foundEntitlementTag = entitledTags.find((tag) => tag.filterKey === currentEntitlementTag.filterKey);
      if (!foundEntitlementTag) {
        const allEntitledTags = [...entitledTags];
        allEntitledTags.push(currentEntitlementTag);
        onChange(allEntitledTags);
      } else {
        foundEntitlementTag.allowedValues = currentEntitlementTag.allowedValues;
        foundEntitlementTag.excludedValues = currentEntitlementTag.excludedValues;
        onChange(entitledTags);
      }
    }
    this.handleModal();
  };

  /**
   * renders the dialog action buttons. If form has any error the save button gets disabled
   * */
  renderDialogActions = () => {
    return (
      <DialogActions>
        <Button onClick={this.handleModal} color="primary">
          Cancel
        </Button>
        <Button onClick={this.onSaveEntitlementTags} color="primary">
          Save
        </Button>
      </DialogActions>
    );
  };

  renderSelectedTags = () => {
    const { entitledTags, classes, t } = this.props;
    return (
      <>
        <Typography variant="body2">Selected Tags</Typography>
        {entitledTags.map((tag: IEntitlementTag) => {
          return (
            <Chip
              size="small"
              key={tag.filterKey}
              icon={<Close />}
              label={t(tag.filterKey)}
              onDelete={() => this.onDeleteEntitledTag(tag.filterKey)}
              className={classes.chip}
            />
          );
        })}
      </>
    );
  };

  /**
   * TODO: Later this functionality should be suppressed
   * deletes the selected entitled tags
   * @param tagFilterKey - Tag filter key to delete
   */
  onDeleteEntitledTag = (tagFilterKey: FilterKey) => {
    const { entitledTags, onChange } = this.props;
    const currentSelectedTags = [...entitledTags];
    const changedTags = currentSelectedTags.filter((tag) => tag.filterKey !== tagFilterKey);
    onChange(changedTags);
  };

  renderButton = () => {
    const { entitlementId, allowEditAlways } = this.props;
    return (
      <Button
        disabled={!!entitlementId && !allowEditAlways}
        variant="contained"
        color="primary"
        onClick={this.handleModal}
      >
        Add / Edit Tags
      </Button>
    );
  };

  renderSelect = () => {
    const { filterKey } = this.state;
    const { t } = this.props;
    return (
      <Select margin="dense" value={filterKey} variant="outlined" onChange={this.onFilterKeyChange} fullWidth>
        {SUPPORTED_FACET_CONFIG.map((inputKey) => {
          return (
            <MenuItem key={inputKey.filterKey} value={inputKey.filterKey}>
              {t(inputKey.filterKey)}
            </MenuItem>
          );
        })}
        <MenuItem key="none" value="none">
          {t('none')}
        </MenuItem>
      </Select>
    );
  };

  render() {
    const { open, filterKey, currentEntitlementTag } = this.state;

    return (
      <>
        {this.renderButton()}
        <Dialog open={open} onClose={this.handleModal} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
          <DialogTitle id="form-dialog-title">Add / Edit Tags</DialogTitle>
          <DialogContent>
            {this.renderSelect()}
            <FormHelperText style={{ marginLeft: '14px' }}>Please select a Filter</FormHelperText>
            {currentEntitlementTag && (
              <>
                <AddEditEntitlementValues
                  filterKey={filterKey}
                  values={currentEntitlementTag.allowedValues}
                  label="Allowed Values"
                  isAllowedValues
                  onAddValue={this.onAddValue}
                  onDeleteValue={this.onDeleteValue}
                />
                <AddEditEntitlementValues
                  filterKey={filterKey}
                  values={currentEntitlementTag.excludedValues}
                  label="Excluded Values"
                  isAllowedValues={false}
                  onAddValue={this.onAddValue}
                  onDeleteValue={this.onDeleteValue}
                />
              </>
            )}
            <div style={{ display: 'block', marginTop: '10px' }}>
              {filterKey === 'none' && this.renderSelectedTags()}
            </div>
          </DialogContent>
          {this.renderDialogActions()}
        </Dialog>
      </>
    );
  }
}

const EntitlementTagModal: React.ComponentClass<IEntitlementTagModalProps, IEntitlementTagModalState> = compose<
  HOCProps,
  IEntitlementTagModalProps
>(
  withTranslation('common'),
  withSnackbar,
  withStyles(entitlementTagModalStyles),
)(EntitlementTagModalView);

export default EntitlementTagModal;
