/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import React from 'react';
import { GridCellParams, GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { EditButton } from '../../common/components/LinkButton';
import { EnableSalesforce } from './components/EnableSalesforce';

export const organisationColumns: GridColumns = [
  {
    renderCell: (params: GridCellParams) => params.row.name,
    field: 'name',
    headerName: 'Organisation Name',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => params.row.emailDomain,
    field: 'emailDomain',
    headerName: 'Email Domain',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => params.row.description,
    field: 'description',
    headerName: 'Description',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => params.row.status,
    field: 'status',
    headerName: 'Status',
    type: 'string',
    flex: 1,
  },
  {
    field: 'edit',
    headerName: 'Actions',
    renderCell: (params: GridCellParams) => {
      return <EditButton path={`/organisation/${params.row.id}/details`} title="Edit Organisation" />;
    },
    sortable: false,
    disableColumnMenu: true,
    flex: 0.5,
  },
  {
    field: 'Enable salesforce',
    headerName: 'Salesforce Integration',
    renderCell: (params: GridRenderCellParams) => {
      return <EnableSalesforce params={params} />;
    },
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
];
