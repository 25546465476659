/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import { FormControl, Grid, InputLabel, TextField } from '@mui/material';
import React from 'react';
import { ObjectId } from 'bson';
import { IUser, Role } from '@vamstar/fox-api-common/esm/user/types';
import { compose } from 'recompose';
import { withSnackbar, ProviderContext } from 'notistack';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IOrganisationUser } from '@vamstar/fox-api-common/esm/organisation-user/types';

import { MultiSelectField } from '../../../common/components/MultiSelectField';
import { PASSWORD_PLACEHOLDER, status, userRoles } from '../../../constants';
import { isSuperAdmin } from '../../../common/utils';

interface IUserFieldsProps {
  _id: string | ObjectId;
  organisationUser: Partial<IOrganisationUser>;
  user: Omit<IUser, 'invalidLoginAttempts'>;
  selectedRoles: [];
  selectedStatus: [];
  isEmailValid: () => boolean;
  isPasswordValid: () => boolean;
  onChangeFirstName: (_e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChangeLastName: (_e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChangeEmail: (_e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChangePassword: (_e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChangeRoles: (_e: any) => void;
  onChangeUserStatus: (_e: any) => void;
}

type HOCProps = IUserFieldsProps & ProviderContext & WithTranslation;

class UserFormFieldsView extends React.Component<HOCProps> {
  renderOrgName = () => {
    const {
      organisationUser: { organisation },
    } = this.props;

    return <TextField fullWidth variant="filled" value={organisation.name} label="Organisation Name" disabled />;
  };

  renderOrgDomainName = () => {
    const {
      organisationUser: { organisation },
    } = this.props;
    return <TextField fullWidth variant="filled" value={organisation.emailDomain} label="Domain Name" disabled />;
  };

  renderFirstName = () => {
    const {
      user: { basicInfo },
      onChangeFirstName,
    } = this.props;
    return (
      <TextField
        fullWidth
        variant="filled"
        value={basicInfo.firstName}
        label="First Name"
        onChange={onChangeFirstName}
      />
    );
  };

  renderLastName = () => {
    const {
      user: { basicInfo },
      onChangeLastName,
    } = this.props;

    return (
      <TextField fullWidth variant="filled" value={basicInfo.lastName} label="Last Name" onChange={onChangeLastName} />
    );
  };

  renderEmail = () => {
    const {
      user: { basicInfo },
      onChangeEmail,
      isEmailValid,
    } = this.props;

    return (
      <TextField
        required
        fullWidth
        variant="filled"
        value={basicInfo.email}
        error={!isEmailValid()}
        label="Email"
        autoComplete="email"
        onChange={onChangeEmail}
      />
    );
  };

  renderPassword = () => {
    const {
      user: { _id, password },
      onChangePassword,
      isPasswordValid,
    } = this.props;

    return (
      <TextField
        required
        variant="filled"
        disabled={_id !== undefined}
        fullWidth
        value={_id ? PASSWORD_PLACEHOLDER : password}
        error={_id ? false : !password || password.length < 6}
        label="Password"
        helperText={isPasswordValid() ? 'Minimum length is 6' : ''}
        type="password"
        autoComplete="current-password"
        onChange={onChangePassword}
      />
    );
  };

  renderRoles = () => {
    const { selectedRoles, onChangeRoles } = this.props;
    const roles = isSuperAdmin() ? userRoles : userRoles.filter((e) => e.name !== Role.SUPER_ADMIN);
    return (
      <FormControl variant="filled" error={selectedRoles.length === 0} fullWidth>
        <InputLabel>Roles</InputLabel>

        <MultiSelectField data={roles} value={selectedRoles} onChange={onChangeRoles} />
      </FormControl>
    );
  };

  renderUserStatus = () => {
    const { selectedStatus, onChangeUserStatus } = this.props;

    return (
      <FormControl variant="filled" error={selectedStatus.length === 0} fullWidth>
        <InputLabel>Status</InputLabel>

        <MultiSelectField data={status} value={selectedStatus} onChange={onChangeUserStatus} />
      </FormControl>
    );
  };

  render() {
    return (
      <Grid container item direction="column" spacing={4}>
        <Grid item>{this.renderOrgName()}</Grid>
        <Grid item>{this.renderOrgDomainName()}</Grid>
        <Grid item>{this.renderFirstName()}</Grid>
        <Grid item>{this.renderLastName()}</Grid>
        <Grid item>{this.renderEmail()}</Grid>
        <Grid item>{this.renderPassword()}</Grid>
        <Grid item>{this.renderRoles()}</Grid>
        <Grid item>{this.renderUserStatus()}</Grid>
      </Grid>
    );
  }
}

export const UserFormFields: React.ComponentClass<IUserFieldsProps, any> = compose<HOCProps, IUserFieldsProps>(
  withSnackbar,
  withTranslation('common'),
)(UserFormFieldsView);
