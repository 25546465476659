/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React, { useState } from 'react';
import { FormControl, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

export interface IAutocompleteFieldProps {
  data: string[];
  label: string;
  onChange: (newValue: string) => void;
  onInputChange?: (newValue: string) => void;
  defaultValue?: string;
  hasError: boolean;
  required: boolean;
  helperText?: string;
  disabled?: boolean;
}

export const AutocompleteField = (props: IAutocompleteFieldProps) => {
  const { data, onChange, onInputChange, label, defaultValue, hasError, required, helperText, disabled } = props;
  const [value, setValue] = useState<string | null>(defaultValue || '');
  const handleChange = (event: any, newValue: string | null): void => {
    setValue(newValue);
    if (newValue) onChange(newValue);
  };

  const handleOnInputChange = (event: any, newValue: string): void => {
    if (onInputChange) onInputChange(newValue);
  };

  return (
    <>
      {data.length > 0 ? (
        <FormControl
          variant="filled"
          fullWidth
          margin="normal"
          error={hasError}
          required={required}
          disabled={disabled}
        >
          <Autocomplete
            id={label}
            value={defaultValue}
            options={data}
            onChange={handleChange}
            onInputChange={handleOnInputChange}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                helperText={helperText}
                variant="filled"
                error={hasError}
                required={required}
                disabled={disabled}
              />
            )}
          />
        </FormControl>
      ) : (
        <TextField fullWidth margin="normal" variant="filled" value={value} label={label} disabled />
      )}
    </>
  );
};
