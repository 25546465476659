/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

export enum OrganisationTab {
  DETAILS = 'details',
  USERS = 'users',
  METRICS = 'metrics',
}
