import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InfoIcon from '@mui/icons-material/Info';
import { Grid, Theme, Tooltip, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { pxToRem } from '@vamstar/fox-frontend-common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      '&:hover': {
        cursor: 'pointer',
      },
      color: theme.palette.black.light,
      fontSize: pxToRem('20'),
    },
    dialogComponents: {
      minWidth: pxToRem('250'),
    },
  }),
);
interface IDialogComponentsProps {
  emailInviteNotSentList: string[];
  emailInviteSentList: string[];
}
const DialogComponents = (props: IDialogComponentsProps) => {
  const { emailInviteNotSentList, emailInviteSentList } = props;
  const classes = useStyles();
  return (
    <Grid container direction="column" spacing={2} className={classes.dialogComponents}>
      {emailInviteNotSentList.length > 0 && (
        <Grid item container direction="column" spacing={1}>
          <Grid item container direction="row" spacing={1}>
            <Grid item>
              <Typography>Email invites not sent</Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Either email is already sent or user already exists.">
                <InfoIcon className={classes.icon} />
              </Tooltip>
            </Grid>
          </Grid>
          {emailInviteNotSentList.map((email) => {
            return (
              <Grid item key={email}>
                {email}
              </Grid>
            );
          })}
        </Grid>
      )}
      {emailInviteSentList.length > 0 && (
        <Grid item container direction="column" spacing={1}>
          <Grid item>
            <Typography>Email invites sent</Typography>
          </Grid>
          {emailInviteSentList.map((email) => {
            return (
              <Grid item key={email}>
                {email}
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};
interface IAlertDialogProps {
  description: {
    [key: string]: string[];
  };
  modalState: boolean;
  handleClose: () => void;
}
const DuplicateSupplierEmailAlert = (props: IAlertDialogProps) => {
  const { description, modalState, handleClose } = props;
  const { emailInviteNotSentList, emailInviteSentList } = description;
  return (
    <div>
      <Dialog
        open={modalState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogComponents
            emailInviteNotSentList={emailInviteNotSentList || []}
            emailInviteSentList={emailInviteSentList || []}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default DuplicateSupplierEmailAlert;
