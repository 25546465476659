/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import { defaultDataIdFromObject, HttpOptions, InMemoryCache, Operation } from '@apollo/client';
import { RequestHeaders } from '@vamstar/fox-api-common/esm/common/enums';

import { DEFAULT_PRODUCT_TYPE } from '../constants/config';
import { getAuthData } from './storage';

export const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    const { __typename, name, description, _id, type } = responseObject;
    switch (__typename) {
      case 'EmEntitlement':
        return `EmEntitlement:${_id || `${name}${description}${type}`}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  },
});

function getApolloFetchOptions(): HttpOptions {
  const DEFAULT_HEADERS = {
    [RequestHeaders.PRODUCT_TYPE]: DEFAULT_PRODUCT_TYPE,
  };
  const authData = getAuthData();
  if (authData) {
    return {
      ...DEFAULT_HEADERS,
      headers: {
        authorization: `Bearer ${authData.token}`,
      },
    };
  }

  return {
    headers: {
      ...DEFAULT_HEADERS,
    },
  };
}

export const request = (operation: Operation) => {
  operation.setContext(getApolloFetchOptions());
};
