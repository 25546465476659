import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Grid, Tab, Tabs, Theme } from '@mui/material';
import { useHistory } from 'react-router';

import { OrganisationTab } from './constants';

interface ITabPanelProps {
  value: string;
}

interface IOrganisationFormWrapperProps {
  tab: OrganisationTab;
  organisationId: string;
  hasLicence?: boolean;
}

const TabPanel = (props: React.PropsWithChildren<ITabPanelProps>) => {
  const { children, value } = props;

  return (
    <div role="tabpanel" id={`organisation-tab-${value}`} aria-labelledby={`organisation-tab-${value}`}>
      <Box p={3}>{children}</Box>
    </div>
  );
};

const a11yProps = (index: string) => {
  return {
    id: `organisation-tab-${index}`,
    'aria-controls': `organisation-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export const OrganisationFormWrapper = (props: React.PropsWithChildren<IOrganisationFormWrapperProps>) => {
  const { tab, organisationId, children, hasLicence } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: string) => {
    history.push({ pathname: `/organisation/${organisationId}/${newValue}` });
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid container item justifyContent="center" direction="column" xs={6}>
        <div className={classes.root}>
          <Tabs
            TabIndicatorProps={{ color: 'primary' }}
            value={tab}
            onChange={handleChange}
            aria-label="Vertical tabs example"
          >
            <Tab label="Details" {...a11yProps(tab)} value={OrganisationTab.DETAILS} />
            <Tab
              label="Users"
              disabled={!organisationId || !hasLicence}
              {...a11yProps(tab)}
              value={OrganisationTab.USERS}
            />
            <Tab
              label="Metrics"
              disabled={!organisationId || !hasLicence}
              {...a11yProps(tab)}
              value={OrganisationTab.METRICS}
            />
          </Tabs>
          <TabPanel value={tab}>{children}</TabPanel>
        </div>
      </Grid>
    </Grid>
  );
};
