/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import React, { Component } from 'react';
import { IOrganisation } from '@vamstar/fox-api-common/esm/organisation/types';
import { DEFAULT_PAGE_SIZE } from '@vamstar/fox-frontend-common';
import { compose } from 'recompose';

import { organisationColumns } from './columns';
import { WithCommonPagination, withCommonPagination } from '../../common/utils/WithCommonPagination';
import { ORGANISATION } from './queries';
import { XGridTableWrapper } from '../../common/components/XGridTableWrapper';
import { CommonXGridWrapper } from './common/CommonXGridWrapper';
import { XGridSearch } from '../../common/components/XGridSearch';

interface IOrganisationTableState {
  organisations: IOrganisation[];
  totalCount: number;
  currentPage: number;
  pageSize: number;
  isLoading: boolean;
  searchText: string;
}

type HOCProps = WithCommonPagination;

class OrganisationTableView extends Component<HOCProps, IOrganisationTableState> {
  public state: IOrganisationTableState = {
    organisations: [],
    totalCount: 0,
    currentPage: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    isLoading: false,
    searchText: '',
  };

  componentDidMount() {
    this.fetchData();
  }

  public fetchData = async () => {
    const { commonPaginationService } = this.props;
    const { pageSize, currentPage, searchText } = this.state;
    const skip = (currentPage - 1) * pageSize;
    this.setState({ isLoading: true });
    const response = await commonPaginationService.fetchPaginatedData<IOrganisation>(
      ORGANISATION,
      skip,
      pageSize,
      searchText,
    );
    if (response !== null) {
      const { items, total } = response.organisations;
      this.setState({ organisations: items, pageSize, totalCount: total });
    }
    this.setState({ isLoading: false });
  };

  onPageSizeChange = (pageSize: number) => {
    this.setState({ pageSize, isLoading: true, currentPage: 1 }, this.fetchData);
  };

  onPageChange = (page: number) => {
    this.setState({ currentPage: page, isLoading: true }, this.fetchData);
  };

  onSearchChange = (searchText: string) => {
    this.setState({ searchText });
  };

  public render() {
    const { isLoading, organisations, totalCount, pageSize, currentPage, searchText } = this.state;
    return (
      <XGridTableWrapper heading="Organisations" route="/organisation/details" buttonLabel="Add Organisation">
        <XGridSearch
          value={searchText}
          onSearchSubmit={() => this.fetchData()}
          onSearchChange={(val) => this.onSearchChange(val)}
        />
        <CommonXGridWrapper
          loading={isLoading}
          columns={organisationColumns}
          rows={organisations}
          count={totalCount}
          page={currentPage}
          rowsPerPage={pageSize}
          onPageChange={this.onPageChange}
          onPageSizeChange={this.onPageSizeChange}
        />
      </XGridTableWrapper>
    );
  }
}

export const OrganisationsTable: React.ComponentClass<any, IOrganisationTableState> = compose<HOCProps, any>(
  withCommonPagination,
)(OrganisationTableView);
