/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { IReport, ReportType } from '@vamstar/fox-api-common/esm/reports';

import { SingleSelectField } from '../../../common/components/SelectField';
import { UploadFiles } from './UploadFile';
import { FileTable } from './FileTable';

interface ReportTemplateProps {
  reportData: IReport;
  onTextFieldChange: (prop: string, value: any) => void;
  onFileChange: (prop: string, value: Record<string, any>) => void;
  allowEditAlways?: boolean;
}

class ReportTemplate extends React.Component<ReportTemplateProps> {
  isDisabled = () => {
    const {
      reportData: { _id },
      allowEditAlways,
    } = this.props;

    return !!_id && !allowEditAlways;
  };

  renderReportTypeDropdown = () => {
    const {
      reportData: { type },
      onTextFieldChange,
    } = this.props;

    return (
      <SingleSelectField
        data={[ReportType.FREE, ReportType.PREMIUM]}
        label="Report Type"
        onChange={(value) => onTextFieldChange('type', value as string)}
        defaultValue={type}
        required
        hasError={false}
      />
    );
  };

  renderReportTextFields = () => {
    const {
      reportData: { title, subTitle },
      onTextFieldChange,
    } = this.props;
    return (
      <>
        <Grid item>
          <TextField
            required
            variant="filled"
            fullWidth
            value={title || ''}
            error={!title || title === ''}
            label="Title"
            onChange={(e) => onTextFieldChange('title', e.target.value as string)}
          />
        </Grid>
        <Grid item>
          <TextField
            variant="filled"
            fullWidth
            value={subTitle || ''}
            label="Sub Title"
            onChange={(e) => onTextFieldChange('subTitle', e.target.value as string)}
          />
        </Grid>
      </>
    );
  };

  renderFileField = () => {
    const {
      reportData: { s3File },
      onFileChange,
    } = this.props;
    if (s3File.originalUrl) {
      return <FileTable file={s3File} title="Report" />;
    }

    return <UploadFiles onChange={(value) => onFileChange('s3File', value)} title="Report" />;
  };

  renderThumbnailField = () => {
    const {
      reportData: { thumbnail },
      onFileChange,
    } = this.props;
    if (thumbnail.originalUrl) {
      return <FileTable file={thumbnail} title="Thumbnail" />;
    }

    return <UploadFiles onChange={(value) => onFileChange('thumbnail', value)} title="Thumbnail" />;
  };

  render() {
    const {
      reportData: { status, description },
      onTextFieldChange,
    } = this.props;

    return (
      <>
        {this.renderReportTextFields()}
        <Grid item>
          <TextField
            variant="filled"
            fullWidth
            value={description || ''}
            label="Description"
            onChange={(e) => onTextFieldChange('description', e.target.value as string)}
          />
        </Grid>
        <Grid item>{this.renderFileField()}</Grid>
        <Grid item>{this.renderThumbnailField()}</Grid>
        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
          {this.renderReportTypeDropdown()}
        </Grid>
        <Grid item>
          <FormControl variant="filled" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              value={status}
              onChange={(e) => onTextFieldChange('status', e.target.value as string)}
            >
              <MenuItem value="PUBLISHED">PUBLISHED</MenuItem>
              <MenuItem value="PRE_RELEASE">PRE_RELEASE</MenuItem>
              <MenuItem value="ARCHIVED">ARCHIVED</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </>
    );
  }
}

export default ReportTemplate;
