/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { gql } from '@apollo/client';

export const USERS = gql`
  query users($data: UserListInput!) {
    users(data: $data) {
      items {
        _id
        basicInfo {
          email
          firstName
          lastName
          roles
          userStatus
        }
        password
      }
      total
      hasMore
    }
  }
`;

export const USER_BY_ID = gql`
  query user($id: String!) {
    user(id: $id) {
      _id
      basicInfo {
        email
        firstName
        lastName
        roles
        userStatus
        jobRole
      }
    }
  }
`;

export const ORGANISATION_BY_USER_ID = gql`
  query getOrganisationUser($userId: ObjectId!) {
    getOrganisationUser(userId: $userId) {
      organisation {
        emailDomain
        name
      }
    }
  }
`;

export const SEARCH_ACTIVE_USERS = gql`
  query searchActiveUsers($searchQuery: String!) {
    searchActiveUsers(searchQuery: $searchQuery) {
      items {
        _id
        basicInfo {
          email
          firstName
          lastName
          roles
        }
      }
      hasMore
      total
    }
  }
`;
