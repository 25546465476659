/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { DEFAULT_DATE_FORMAT } from '@vamstar/fox-api-common/esm/constants';
import { Role } from '@vamstar/fox-api-common/esm/user/types';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { getAuthData } from '../util/storage';

export const getArrayMap = (arr: any[]) => {
  return arr.map((item: any) => ({ ...item, id: item._id }));
};

export const isSuperAdmin = () => {
  const authData = getAuthData();

  return authData && authData.basicUserInfo.roles.includes(Role.SUPER_ADMIN);
};

export const getSeatsIncluded = (basePrice?: number, pricePerSeat?: number): number => {
  return Math.floor((basePrice || 0) / (pricePerSeat || 1));
};

export const getFormattedDate = (date: string) => {
  return format(parseISO(date), DEFAULT_DATE_FORMAT);
};
