/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React from 'react';
import UsersIcon from '@mui/icons-material/Group';
import AddBoxIcon from '@mui/icons-material/AddBox';
import TimelineIcon from '@mui/icons-material/Timeline';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ExposureOutlined from '@mui/icons-material/ExposureOutlined';
import ExposureIcon from '@mui/icons-material/Exposure';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import EqualizerIcon from '@mui/icons-material/Equalizer';

import UsersTable from '../users/UsersTable';
import { ICustomRouteProps } from '../../navigation/interfaces';
import EntitlementTemplatesTable from '../entitlement-template/EntitlementTemplatesTable';
import { SystemActivity } from '../system-activity/SystemActivity';
import { OrganisationsTable } from '../organisation/OrganisationsTable';
import { OrganisationForm } from '../organisation/forms/OrganisationForm';
import { OrganisationUsersForm } from '../organisation/forms/OrganisationUsersForm';
import LicenceTemplatesTable from '../licence-template/LicenceTemplatesTable';
import User from '../users/forms/UserForm';
import LicenceTemplate from '../licence-template/forms/LicenceTemplateForm';
import Licence from '../licence/forms/LicenceForm';
import EntitlementTemplateForm from '../entitlement-template/forms/EntitlementTemplateForm';
import LicencesTable from '../licence/LicencesTable';
import InviteSuppliersForm from '../invite-suppliers/components/InviteSuppliers';
import InvitedSuppliersTable from '../invite-suppliers/InvitedSuppliersTable';
import { OrganisationMetrics } from '../organisation/forms/OrganisationMetrics';
import ReportTable from '../reports/ReportTable';
import ReportForm from '../reports/forms/ReportForm';

export const dashboardRoutes: ICustomRouteProps[] = [
  {
    icon: <TimelineIcon />,
    path: '/system-activity',
    title: 'System Activity',
    component: SystemActivity,
    exact: true,
    inSideBar: true,
    isPrivate: true,
  },
  {
    icon: <UsersIcon />,
    path: '/users',
    title: 'Users',
    component: UsersTable,
    exact: true,
    isPrivate: true,
    inSideBar: true,
  },
  {
    path: '/user/:_id?',
    title: 'Users',
    component: User,
    exact: true,
    isPrivate: true,
    inSideBar: false,
  },
  {
    icon: <AddBoxIcon />,
    path: '/entitlement-templates',
    title: 'Entitlement Templates',
    component: EntitlementTemplatesTable,
    exact: true,
    inSideBar: true,
    isPrivate: true,
  },
  {
    icon: <AddBoxIcon />,
    path: '/entitlement-template/:_id?',
    title: 'Create/Edit Entitlement Template',
    component: EntitlementTemplateForm,
    exact: true,
    inSideBar: false,
    isPrivate: true,
  },
  {
    icon: <SupervisedUserCircleIcon />,
    path: '/organisations',
    title: 'Organisations',
    component: OrganisationsTable,
    exact: true,
    inSideBar: true,
    isPrivate: true,
  },
  {
    path: '/organisation/:id?/details',
    title: 'OrganisationForm',
    component: OrganisationForm,
    exact: true,
    isPrivate: true,
    inSideBar: false,
  },
  {
    path: '/organisation/:id/users',
    title: 'OrganisationUsersForm',
    component: OrganisationUsersForm,
    exact: true,
    isPrivate: true,
    inSideBar: false,
  },
  {
    path: '/organisation/:id/metrics',
    title: 'OrganisationMetrics',
    component: OrganisationMetrics,
    exact: true,
    isPrivate: true,
    inSideBar: false,
  },
  {
    path: '/licence-template/:_id?',
    title: 'Licence Template',
    component: LicenceTemplate,
    exact: true,
    inSideBar: false,
    isPrivate: true,
  },
  {
    icon: <ExposureOutlined />,
    path: '/licence-templates',
    title: 'Licence Templates',
    component: LicenceTemplatesTable,
    exact: true,
    inSideBar: true,
    isPrivate: true,
  },
  {
    icon: <ExposureIcon />,
    path: '/licences',
    title: 'Licences',
    component: LicencesTable,
    exact: true,
    inSideBar: true,
    isPrivate: true,
  },
  {
    path: '/licence/:_type?/:_id?',
    title: 'Licence',
    component: Licence,
    exact: true,
    inSideBar: false,
    isPrivate: true,
  },
  {
    icon: <EmailRoundedIcon />,
    path: '/invite-suppliers',
    title: 'Supplier Invitations',
    component: InvitedSuppliersTable,
    exact: true,
    inSideBar: true,
    isPrivate: true,
  },
  {
    path: '/invite-supplier',
    title: 'Invite Supplier',
    component: InviteSuppliersForm,
    exact: true,
    inSideBar: false,
    isPrivate: true,
  },
  {
    icon: <EqualizerIcon />,
    path: '/reports',
    title: 'Reports',
    component: ReportTable,
    exact: true,
    inSideBar: true,
    isPrivate: true,
  },
  {
    icon: <EqualizerIcon />,
    path: '/report/:_id?',
    title: 'Create/Edit Report Template',
    component: ReportForm,
    exact: true,
    inSideBar: false,
    isPrivate: true,
  },
];
