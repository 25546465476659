/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React from 'react';
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { IEmEntitlement } from '@vamstar/fox-api-common/esm/entitlement-template/types';
import uniqueId from 'lodash/uniqueId';

interface IEntitlementsTableProps {
  rights: IEmEntitlement[];
  editEntitlementTemplate: (index: number) => void;
  deleteEntitlementTemplate: (index: number) => void;
  addNewEntitlementTemplate: () => void;
}
class EntitlementsTable extends React.Component<IEntitlementsTableProps> {
  renderRights = () => {
    const { rights, editEntitlementTemplate, deleteEntitlementTemplate } = this.props;
    return rights.map((item: IEmEntitlement, index: number) => (
      <TableRow key={uniqueId()}>
        <TableCell component="th" scope="row" align="center">
          {item.name}
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {item.type}
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          <Tooltip title="Edit Entitlement">
            <IconButton onClick={() => editEntitlementTemplate(index)} size="large">
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Entitlement">
            <IconButton onClick={() => deleteEntitlementTemplate(index)} size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    ));
  };

  render() {
    const { addNewEntitlementTemplate } = this.props;

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderRights()}
            <TableRow>
              <TableCell component="th" scope="row" align="right" colSpan={3}>
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={addNewEntitlementTemplate}>
                  <Typography variant="button">Add Custom Entitlement</Typography>
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
export default EntitlementsTable;
