import React from 'react';
import { Grid, TextField } from '@mui/material';
import { ILicenceTemplate } from '@vamstar/fox-api-common/esm/licence-template/types';

import { BasePrice, PricePerSeat } from '../../../common/components/BasePriceAndPricePerSeat';

interface INumberOfSeatsRendererProps {
  licenceTemplateData: Partial<ILicenceTemplate>;
  onChange: (prop: string, value: any) => void;
}

export const NumberOfSeatsRenderer = (props: INumberOfSeatsRendererProps) => {
  const {
    licenceTemplateData: { numberOfSeats },
    onChange,
  } = props;

  return (
    <Grid item>
      <TextField
        disabled
        fullWidth
        required
        variant="filled"
        value={numberOfSeats}
        error={!numberOfSeats || numberOfSeats < 0}
        label="Number of seats"
        onChange={(e) => onChange('numberOfSeats', +e.target.value)}
      />
    </Grid>
  );
};

interface IBasePriceRendererProps {
  licenceTemplateData: Partial<ILicenceTemplate>;
  onChange: (prop: string, value: any) => void;
}

export const BasePriceAndPricePerSeatRenderer = (props: IBasePriceRendererProps) => {
  const { licenceTemplateData, onChange } = props;

  return (
    <>
      <Grid item>
        <BasePrice data={licenceTemplateData} disabled={!!licenceTemplateData._id} onChange={onChange} />
      </Grid>
      <Grid item>
        <PricePerSeat data={licenceTemplateData} disabled={!!licenceTemplateData._id} onChange={onChange} />
      </Grid>
    </>
  );
};
