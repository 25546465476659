/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { log } from '@vamstar/fox-node-logger';
import Axios, { AxiosRequestConfig } from 'axios';

export const uploadFile = async (signedUrl: string, file: File) => {
  if (file) {
    const config: AxiosRequestConfig = {
      headers: { 'Content-Type': file.type },
    };
    delete Axios.defaults.headers.common.Authorization;
    try {
      await Axios.put(signedUrl, file, config);
    } catch (error) {
      log.error('S3_UPLOAD_ERROR', error);
      throw new Error(`${error}`);
    }
  }
};
