/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { gql } from '@apollo/client';

import { EmEntitlementFragment } from '../entitlement-template/fragments';

export const CREATE_LICENCE = gql`
  mutation createLicence($data: LicenceInput!) {
    createLicence(data: $data) {
      _id
      licenceTemplate
      name
      status
      startDate
      endDate
      rights {
        ...EmEntitlementFields
      }
      basePrice {
        currency
        value
      }
      pricePerSeat {
        currency
        value
      }
      user {
        user
        firstName
        lastName
        email
      }
      organisation {
        _id
        name
        emailDomain
        slug
      }
    }
  }
  ${EmEntitlementFragment.emEntitlementFields}
`;

export const UPDATE_LICENCE = gql`
  mutation updateLicence($data: LicenceUpdateInput!) {
    updateLicence(data: $data) {
      _id
      name
      startDate
      endDate
      status
    }
  }
`;
