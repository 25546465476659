/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import {
  EntitlementStatus,
  EntitlementType,
  IEmEntitlement,
  IEntitlementTemplate,
} from '@vamstar/fox-api-common/esm/entitlement-template/types';
import { ILicence, LicenceType } from '@vamstar/fox-api-common/esm/licence/types';
import { ApolloClient } from '@apollo/client';
import { ObjectId } from 'bson';
import { IEmUser, IUser } from '@vamstar/fox-api-common/esm/user/types';
import { ILicenceTemplate } from '@vamstar/fox-api-common/esm/licence-template/types';
import { IEmOrganisation, IOrganisation } from '@vamstar/fox-api-common/esm/organisation/types';
import { VamProduct } from '@vamstar/fox-api-common/esm/vam-product/types';
import { IReport } from '@vamstar/fox-api-common/esm/reports';
import cloneDeep from 'lodash/cloneDeep';
import { IMoney } from '@vamstar/fox-api-common/esm/common/types';
import { log } from '@vamstar/fox-node-logger';
import { DateUtils } from '@vamstar/fox-api-common/esm/utils';

import { FIND_LICENCE_BY_ID } from './queries';
import { LICENCE_TEMPLATE } from '../licence-template/queries';
import { SEARCH_ACTIVE_USERS } from '../users/queries';
import { ORGANISATION } from '../organisation/queries';
import { fetchData as entitlementFetchData } from '../entitlement-template/utils';
import { CREATE_LICENCE, UPDATE_LICENCE } from './mutations';
import { deepOmitPropArray, removeProp } from '../../util/object';
import { fetchLicenceTemplateById } from '../licence-template/utils';
import { DEFAULT_TAKE } from '../../constants/pagination';

interface ICountry {
  name: string;
  isoCode: string;
}

interface IData {
  licenceData: Partial<ILicence>;
  licenceTemplateData: ILicenceTemplate[];
  usersData: IEmUser[];
  organisationsData: IOrganisation[];
  products: VamProduct[];
  reports: IReport[];
  countries: ICountry[];
  elasticDsl: string;
}

let data: IData = {
  licenceData: {},
  licenceTemplateData: [],
  usersData: [],
  organisationsData: [],
  products: [],
  reports: [],
  countries: [],
  elasticDsl: '',
};

const validateName = (name?: string): boolean => {
  return !name || name === '';
};

const validateUserAndOrganisation = (user?: IEmUser, organisation?: IOrganisation): boolean => {
  return !user && !organisation;
};

const validateBasePrice = (basePrice?: IMoney): boolean => {
  return !basePrice || !basePrice.value || basePrice.value < 1;
};

const validatePricePerSeat = (pricePerSeat?: IMoney): boolean => {
  return !pricePerSeat || !pricePerSeat.value || pricePerSeat.value < 1;
};

export const isSaveDisabled = (licenceData: Partial<ILicence>): boolean => {
  const { name, user, organisation, basePrice, pricePerSeat, startDate, endDate } = licenceData;
  return (
    validateName(name) ||
    validateUserAndOrganisation(user, organisation) ||
    validateBasePrice(basePrice) ||
    validatePricePerSeat(pricePerSeat) ||
    !DateUtils.toISOString(startDate) ||
    !DateUtils.toISOString(endDate)
  );
};

const convertUserObject = (userData: IUser[]) => {
  const users = userData.map((user) => {
    return {
      email: user.basicInfo.email,
      firstName: user.basicInfo.firstName,
      lastName: user.basicInfo.lastName,
      roles: user.basicInfo.roles,
      user: user._id,
    };
  });

  return users;
};

const getLicenceById = async (client: ApolloClient<any>, id: string | ObjectId, type: LicenceType) => {
  try {
    const response = await client.mutate({
      mutation: FIND_LICENCE_BY_ID,
      variables: {
        id,
        type,
      },
    });

    data.licenceData = response.data.findLicence;
  } catch (error) {
    log.error(JSON.stringify(error));
  }
};

const fetchLicenceTemplateData = async (client: ApolloClient<any>) => {
  try {
    const licenceTemplateResponse = await client.query({
      query: LICENCE_TEMPLATE,
      variables: {
        data: {
          skip: 0,
          take: DEFAULT_TAKE,
        },
      },
    });

    data.licenceTemplateData = licenceTemplateResponse.data.licencetemplates.items;
  } catch (error) {
    log.error(JSON.stringify(error));
  }
};

export const fetchUsersData = async (client: ApolloClient<any>, searchQuery: string = ''): Promise<IEmUser[]> => {
  try {
    const userResponse = await client.query({
      query: SEARCH_ACTIVE_USERS,
      variables: {
        searchQuery,
        skip: 0,
        take: DEFAULT_TAKE,
      },
    });
    // console.log('>>>', userResponse.data.searchActiveUsers);
    // return userResponse.data.searchActiveUsers;
    const { items } = userResponse.data.searchActiveUsers;
    return convertUserObject(items);
  } catch (error) {
    log.error(JSON.stringify(error));
    return [];
  }
};

const fetchOrganisationData = async (client: ApolloClient<any>) => {
  try {
    const organisationResponse = await client.query({
      query: ORGANISATION,
      variables: {
        data: {
          skip: 0,
          take: DEFAULT_TAKE,
        },
      },
    });

    data.organisationsData = organisationResponse.data.organisations.items;
  } catch (error) {
    log.error(JSON.stringify(error));
  }
};

export const fetchData = async (
  client: ApolloClient<any>,
  type: LicenceType,
  _id?: string | ObjectId,
): Promise<IData> => {
  data = {
    licenceData: {},
    licenceTemplateData: [],
    usersData: [],
    organisationsData: [],
    products: [],
    reports: [],
    countries: [],
    elasticDsl: '',
  };

  if (_id) {
    await getLicenceById(client, _id, type);

    if (data.licenceData.licenceTemplate) {
      const licencetemplateData = await fetchLicenceTemplateById(client, data.licenceData.licenceTemplate as string);
      data.licenceTemplateData = [licencetemplateData];
    }

    if (data.licenceData.user) {
      data.usersData = [data.licenceData.user];
    } else if (data.licenceData.organisation) {
      data.organisationsData = [data.licenceData.organisation];
    }
  } else {
    await fetchLicenceTemplateData(client);

    data.usersData = await fetchUsersData(client);

    await fetchOrganisationData(client);
  }

  const { products, reports, countries } = await entitlementFetchData(client);

  data.products = products;
  data.reports = reports;
  data.countries = countries;

  return data;
};

const __toEmOrganisation = (organisation: IOrganisation): IEmOrganisation => {
  return {
    _id: organisation._id,
    emailDomain: organisation.emailDomain,
    name: organisation.name,
    slug: organisation.slug,
    status: organisation.status,
    workDocsProperties: organisation.workDocsProperties,
    type: organisation.type,
  };
};

export const onLicenceSave = (
  client: ApolloClient<any>,
  licenceData: Partial<ILicence>,
  selectedLicenceTemplateId?: string,
) => {
  const newRights = deepOmitPropArray(licenceData.rights, '__typename');
  newRights[0].reports = deepOmitPropArray(newRights[0].reports, 'name');
  const newOrganisation = licenceData.organisation
    ? __toEmOrganisation(removeProp(licenceData.organisation, '__typename'))
    : null;

  return client.mutate({
    mutation: CREATE_LICENCE,
    variables: {
      data: {
        rights: newRights,
        basePrice: licenceData.basePrice,
        name: licenceData.name,
        status: licenceData.status,
        type: licenceData.type,
        user: licenceData.user,
        organisation: newOrganisation,
        pricePerSeat: licenceData.pricePerSeat,
        numberOfSeats: licenceData.numberOfSeats,
        seatsIncluded: licenceData.seatsIncluded,
        licenceTemplate: selectedLicenceTemplateId,
        startDate: licenceData.startDate,
        endDate: licenceData.endDate,
      },
    },
  });
};

export const onLicenceUpdate = (client: ApolloClient<any>, licenceData: Partial<ILicence>) => {
  const { name, numberOfSeats, startDate, endDate, status } = licenceData;
  let { basePrice, pricePerSeat } = licenceData;
  basePrice = basePrice && removeProp(basePrice, '__typename');
  pricePerSeat = pricePerSeat && removeProp(pricePerSeat, '__typename');

  const newRights: IEmEntitlement[] = deepOmitPropArray(licenceData.rights, '__typename');
  newRights.forEach((rights) => {
    rights.reports = deepOmitPropArray(rights.reports, 'name'); // eslint-disable-line no-param-reassign
  });

  return client.mutate({
    mutation: UPDATE_LICENCE,
    variables: {
      data: {
        filter: { id: licenceData._id },
        doc: { name, rights: newRights, basePrice, pricePerSeat, numberOfSeats, startDate, endDate, status },
      },
    },
  });
};

export const createEmEntitlement = (entitlementtemplateData: Partial<IEntitlementTemplate>): IEmEntitlement => {
  return {
    name: entitlementtemplateData.name as string,
    status: entitlementtemplateData.status as EntitlementStatus,
    type: entitlementtemplateData.type as EntitlementType,
    _id: entitlementtemplateData._id,
    description: entitlementtemplateData.description,
    geography: entitlementtemplateData.geography,
    paths: entitlementtemplateData.paths,
    reports: entitlementtemplateData.reports,
    tags: entitlementtemplateData.tags,
    vamProducts: entitlementtemplateData.vamProducts,
    elasticDsl: entitlementtemplateData.elasticDsl,
  };
};

export const getRights = (rights?: IEmEntitlement[], licenceTemplate?: ILicenceTemplate) => {
  const licenceTemplateRights = (licenceTemplate && cloneDeep(licenceTemplate.rights)) || [];

  const newRights = (rights && rights.filter((right) => !right._id)) || [];
  return [...newRights, ...licenceTemplateRights];
};
