/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import React from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';

import { EditButton } from '../../common/components/LinkButton';

export const reportColumns: GridColDef[] = [
  {
    renderCell: (params: GridCellParams) => params.row.title,
    field: 'title',
    headerName: 'Title',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => params.row.subTitle,
    field: 'subTitle',
    headerName: 'Sub Title',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => params.row.description,
    field: 'description',
    headerName: 'Description',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => params.row.s3File.originalUrl,
    field: 's3File',
    headerName: 'Report',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => {
      const imageEndUrl = params.row.thumbnail.originalUrl;
      const imageUrl = `${process.env.REACT_APP_S3_REPORTS_THUMBNAIL_BUCKET}/${imageEndUrl}`;
      return (
        <a href={imageUrl} target="_blank" rel="noopener noreferrer">
          <img src={imageUrl} alt={imageEndUrl} style={{ width: 50 }} />
        </a>
      );
    },
    field: 'thumbnail',
    headerName: 'Thumbnail',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => params.row.status,
    field: 'status',
    headerName: 'Status',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => params.row.type,
    field: 'type',
    headerName: 'Type',
    type: 'string',
    flex: 1,
  },
  {
    field: 'edit',
    headerName: 'Actions',
    renderCell: (params: GridCellParams) => {
      return <EditButton path={`/report/${params.row.id}`} title="Edit Report" />;
    },
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
];
