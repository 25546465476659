/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React, { Component, ComponentType } from 'react';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { IApolloClient } from '@vamstar/fox-frontend-common';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { compose } from 'recompose';
import { Grid } from '@mui/material';
import { SystemActivityRefPath } from '@vamstar/fox-api-common/esm/system-activity';
import loFind from 'lodash/find';
import { log } from '@vamstar/fox-node-logger';

import { GET_SYSTEM_ACTIVITIES, GET_ACTIVITY_COUNT } from './queries';
import { ISystemActivityData } from './interface';
import { ActivityChart } from './components/ActivityChart';
import { ActivityCard } from './components/ActivityCard';

interface ISystemActivityState {
  userActivityData: ISystemActivityData[];
  searchActivityData: ISystemActivityData[];
  totalUsers: number;
  totalSavedSearch: number;
  isLoading: boolean;
}

type HOCProps = WithApolloClient<IApolloClient>;

export class SystemActivityView extends Component<HOCProps, ISystemActivityState> {
  public state: ISystemActivityState = {
    userActivityData: [],
    searchActivityData: [],
    totalUsers: 0,
    totalSavedSearch: 0,
    isLoading: true,
  };

  componentDidMount() {
    this.fetchSystemActivities();
    this.fetchActivitiesCount();
  }

  public fetchSystemActivities = async () => {
    const { client } = this.props;
    try {
      const response = await client.query({
        query: GET_SYSTEM_ACTIVITIES,
        variables: {
          type: [SystemActivityRefPath.User, SystemActivityRefPath.CapturedSearch],
          fetchPolicy: 'network-only',
        },
      });

      const activityData: ISystemActivityData[] = response.data.getSystemActivities;

      const userActivityData = loFind(activityData, ['id', 'User']) || activityData[0];
      const searchActivityData = loFind(activityData, ['id', 'CapturedSearch']) || activityData[1];
      this.setState({
        userActivityData: [this.annotateActivityData(userActivityData, 'hsl(42, 70%, 50%)')],
        searchActivityData: [this.annotateActivityData(searchActivityData, 'hsl(3, 70%, 50%)', 'Flatlot')],
      });
    } catch (error) {
      log.error(JSON.stringify(error));
    } finally {
      this.setState({ isLoading: false });
    }
  };

  annotateActivityData = (activityData: ISystemActivityData, customColor?: string, customId?: string) => {
    const { id, data } = activityData;
    return {
      id: customId || id,
      data,
      color: customColor || 'hsl(121, 70%, 50%)',
    };
  };

  public fetchActivitiesCount = async () => {
    const { client } = this.props;
    try {
      const response = await client.query({
        query: GET_ACTIVITY_COUNT,
        variables: {
          userData: {},
          savedSearchesData: {},
          fetchPolicy: 'network-only',
        },
      });
      const { users, savedsearchs } = response.data;
      this.setState({ totalUsers: users.total, totalSavedSearch: savedsearchs.total });
    } catch (error) {
      log.error(JSON.stringify(error));
    } finally {
      this.setState({ isLoading: false });
    }
  };

  renderActivityCards = () => {
    const { totalUsers, totalSavedSearch } = this.state;
    return (
      <>
        <Grid container spacing={3}>
          <Grid item>
            <ActivityCard activityCount={totalUsers} activityName="users" icon={<PersonIcon />} />
          </Grid>
          <Grid item>
            <ActivityCard activityCount={totalSavedSearch} activityName="saved searches" icon={<SearchIcon />} />
          </Grid>
        </Grid>
      </>
    );
  };

  render() {
    const { userActivityData, searchActivityData, isLoading } = this.state;

    return (
      <>
        {this.renderActivityCards()}
        <div style={{ paddingTop: '20px' }}>
          <Grid container spacing={3} direction="column">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ActivityChart data={userActivityData} isLoading={isLoading} graphTitle="Daily Login Count" />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ActivityChart data={searchActivityData} isLoading={isLoading} graphTitle="Daily Searches" />
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export const SystemActivity = compose(withApollo)(SystemActivityView as ComponentType<unknown>);
