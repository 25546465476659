/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import * as React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import AddRounded from '@mui/icons-material/AddRounded';
import { Link } from 'react-router-dom';
import { pxToRem } from '@vamstar/fox-frontend-common';

import { isSuperAdmin } from '../utils';

interface ITableWrapperProps {
  heading: string;
  route: string;
  buttonLabel: string;
  checkSuperAdmin?: boolean;
}

const XGridTableAddButton = (props: { route: string; buttonLabel: string }) => {
  const { route, buttonLabel } = props;
  return (
    <Link to={route} style={{ textDecoration: 'none' }}>
      <Button variant="contained" color="primary" startIcon={<AddRounded />}>
        <Typography variant="h6">{buttonLabel}</Typography>
      </Button>
    </Link>
  );
};

export const XGridTableWrapper = (props: React.PropsWithChildren<ITableWrapperProps>) => {
  const { heading, children, checkSuperAdmin } = props;
  return (
    <Grid container direction="column">
      <Grid container item direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{heading}</Typography>
        {checkSuperAdmin ? isSuperAdmin() && <XGridTableAddButton {...props} /> : <XGridTableAddButton {...props} />}
      </Grid>
      <Grid item style={{ marginTop: pxToRem('8px') }}>
        {children}
      </Grid>
    </Grid>
  );
};
