import { ApolloClient } from '@apollo/client';
import { IntegrationApp, IntegrationStatus } from '@vamstar/fox-api-common/esm/organisation/types';
import { IElasticSearchFacetsResponse } from '@vamstar/fox-api-common/esm/common/types';
import { SUPPORTED_FACET_CONFIG } from '@vamstar/fox-api-common/esm/constants';
import loUniqueId from 'lodash/uniqueId';

import { IOrganisationMetric } from './interfaces';
import { ORGANISATION_BY_ID } from './queries';

export const getOrganisationById = async (client: ApolloClient<any>, id: string) => {
  const response = await client.query({
    query: ORGANISATION_BY_ID,
    variables: {
      id,
    },
  });
  return response.data.findOrganisation;
};

export const getSalesforceIntegrationObject = (status: IntegrationStatus) => {
  return {
    app: IntegrationApp.SALESFORCE,
    status,
    lastSyncDate: new Date(),
  };
};

const __getUpdatedMetrics = (keyword: string, filterKey: string, property: string, count: number) => {
  return {
    id: loUniqueId(),
    keyword,
    openTendersCount: 0,
    dashboardCount: 0,
    tagType: filterKey,
    totalCount: 0,
    [property]: count,
  };
};

export const enrichOrganisationMetrics = (
  organisationMetricsMap: Map<string, IOrganisationMetric>,
  aggregation: IElasticSearchFacetsResponse,
  property: keyof IOrganisationMetric,
) => {
  SUPPORTED_FACET_CONFIG.forEach((config) => {
    const { filterKey } = config;
    if (aggregation && aggregation[filterKey]) {
      const { buckets } = aggregation[filterKey];
      buckets.forEach((bucket) => {
        const keyword = bucket.key.trim();
        const metric = organisationMetricsMap.get(keyword);
        if (metric) {
          metric[property.toString()] = bucket.doc_count;
        } else {
          organisationMetricsMap.set(keyword, __getUpdatedMetrics(keyword, filterKey, property, bucket.doc_count));
        }
      });
    }
  });
};
