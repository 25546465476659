/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React, { Component } from 'react';
import { ILicence } from '@vamstar/fox-api-common/esm/licence/types';
import { DEFAULT_PAGE_SIZE, XGridTable } from '@vamstar/fox-frontend-common';
import { compose } from 'recompose';
import { log } from '@vamstar/fox-node-logger';

import { columns } from './columns';
import { LICENCE } from './queries';
import { WithCommonPagination, withCommonPagination } from '../../common/utils/WithCommonPagination';
import { XGridTableWrapper } from '../../common/components/XGridTableWrapper';
import { XGridSearch } from '../../common/components/XGridSearch';

interface ILicenceTableState {
  licenceData: ILicence[];
  isLoading: boolean;
  totalCount: number;
  currentPage: number;
  pageSize: number;
  searchText: string;
}

type HOCProps = WithCommonPagination;

class LicenceTableView extends Component<HOCProps, ILicenceTableState> {
  state: ILicenceTableState = {
    licenceData: [],
    isLoading: false,
    currentPage: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
    searchText: '',
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ isLoading: true });

    const { commonPaginationService } = this.props;
    const { pageSize, currentPage, searchText } = this.state;
    try {
      const skip = (currentPage - 1) * pageSize;
      const licenceResponse = await commonPaginationService.fetchPaginatedData<ILicence>(
        LICENCE,
        skip,
        pageSize,
        searchText,
      );
      if (licenceResponse !== null) {
        const { items, total } = licenceResponse.licences;
        this.setState({ licenceData: items, totalCount: total, pageSize });
      }
    } catch (error) {
      log.error(JSON.stringify(error));
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onPageSizeChange = (newPageSize: number) => {
    this.setState({ pageSize: newPageSize, currentPage: 1 }, () => this.fetchData());
  };

  onPageChange = (page: number) => {
    this.setState({ currentPage: page }, () => this.fetchData());
  };

  onSearchChange = (searchText: string) => {
    this.setState({ searchText });
  };

  render() {
    const { isLoading, licenceData, totalCount, pageSize, currentPage, searchText } = this.state;
    return (
      <XGridTableWrapper heading="Licences" route="/licence" buttonLabel="Add Licence">
        <XGridSearch
          value={searchText}
          onSearchSubmit={() => this.fetchData()}
          onSearchChange={(val) => this.onSearchChange(val)}
        />
        <XGridTable
          columns={columns}
          rows={licenceData}
          count={totalCount}
          loading={isLoading}
          onPageChange={this.onPageChange}
          onPageSizeChange={this.onPageSizeChange}
          page={currentPage}
          rowsPerPage={pageSize}
        />
      </XGridTableWrapper>
    );
  }
}

const LicencesTable: React.ComponentClass<any, ILicenceTableState> = compose<HOCProps, any>(withCommonPagination)(
  LicenceTableView,
);

export default LicencesTable;
