/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const GRAPH_HEIGHT = 62;
const SUB_HEADER = 500;

export const useSystemActivityStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    card: {
      minHeight: '100%',
      minWidth: '100%',
      height: '100%',
      width: '100%',
    },
    subHeader: {
      color: theme.palette.grey[SUB_HEADER],
    },
    graphContainer: {
      minWidth: '100%',
      height: theme.spacing(GRAPH_HEIGHT),
      cursor: 'pointer',
    },
  }),
);

export const useActivityCardStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: '200px',
      height: '210px',
    },
    grid: {
      display: 'flex',
      justifyContent: 'center' as 'center',
    },
  }),
);
