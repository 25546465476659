import React, { Key } from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { ILicence, LicenceType } from '@vamstar/fox-api-common/esm/licence/types';
import { ILicenceTemplate } from '@vamstar/fox-api-common/esm/licence-template/types';
import { IEmUser } from '@vamstar/fox-api-common/esm/user/types';
import { IOrganisation } from '@vamstar/fox-api-common/esm/organisation/types';

import { LicenceDatePicker } from './LicenceDatePicker';
import { CURRENCY } from '../../../constants';
import { SingleSelectField } from '../../../common/components/SelectField';
import { AutocompleteField } from '../../../common/components/AutocompleteField';

interface ILicenceDateRendererProps {
  startDate?: string | Date;
  endDate?: string | Date;
  onChange: (prop: string, value: any) => void;
}

export const LicenceDateRenderer = (props: ILicenceDateRendererProps) => {
  const { startDate, endDate, onChange } = props;
  return (
    <Grid item container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item xs={5}>
        <LicenceDatePicker
          currentDate={startDate as string}
          onChange={(data) => onChange('startDate', data)}
          id="startDate"
          title="Start Date"
        />
      </Grid>
      <Grid item xs={5}>
        <LicenceDatePicker
          currentDate={endDate as string}
          onChange={(data) => onChange('endDate', data)}
          id="endDate"
          title="End Date"
          minDate={endDate as string}
        />
      </Grid>
    </Grid>
  );
};

interface INameRendererProps {
  name?: string;
  onChange: (prop: string, value: any) => void;
}

export const NameRenderer = (props: INameRendererProps) => {
  const { name, onChange } = props;

  return (
    <Grid item>
      <TextField
        required
        fullWidth
        variant="filled"
        value={name || ''}
        error={!name || name === ''}
        label="Name"
        onChange={(e) => onChange('name', e.target.value)}
      />
    </Grid>
  );
};

interface ILicenceTemplateRendererProps {
  licenceData: Partial<ILicence>;
  licenceTemplateData: ILicenceTemplate[];
  selectedLicenceTemplateId?: string;
  onLicenceTemplateChange: (event: SelectChangeEvent<string>) => void;
}

export const LicenceTemplateRenderer = (props: ILicenceTemplateRendererProps) => {
  const {
    licenceData: { _id },
    licenceTemplateData,
    selectedLicenceTemplateId,
    onLicenceTemplateChange,
  } = props;

  return (
    <Grid item xs={12}>
      <FormControl variant="filled" fullWidth disabled={!!_id}>
        <InputLabel>Licence Template</InputLabel>
        <Select
          fullWidth
          label="Licence Template"
          value={typeof selectedLicenceTemplateId === 'string' ? selectedLicenceTemplateId : ''}
          onChange={(event) => onLicenceTemplateChange(event)}
        >
          {licenceTemplateData.map((item) => {
            return (
              <MenuItem key={item._id as Key} value={item._id as string}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
};

interface IUserRendererProps {
  licenceData: Partial<ILicence>;
  usersData: IEmUser[];
  onChange: (prop: string, value: any) => void;
  onInputChange: (value: string) => void;
}

export const UserRenderer = (props: IUserRendererProps) => {
  const {
    licenceData: { _id, user },
    usersData,
    onChange,
    onInputChange,
  } = props;

  const users = usersData.map((e) => e.email);
  return (
    <Grid item xs={5}>
      <AutocompleteField
        data={users}
        label="Users"
        onChange={(value) => {
          const selectedUser = usersData.find((element) => element.email === value);
          onChange('user', selectedUser);
        }}
        onInputChange={onInputChange}
        defaultValue={user && user.email}
        required
        hasError={!user}
        disabled={!!_id}
      />
    </Grid>
  );
};

interface IOrganisationRendererProps {
  licenceData: Partial<ILicence>;
  organisationsData: IOrganisation[];
  onChange: (prop: string, value: any) => void;
}

export const OrganisationRenderer = (props: IOrganisationRendererProps) => {
  const {
    licenceData: { _id, organisation },
    organisationsData,
    onChange,
  } = props;

  const organisations = organisationsData.map((e) => e.emailDomain);
  return (
    <Grid item xs={5}>
      <SingleSelectField
        data={organisations as string[]}
        label="Organisation"
        onChange={(value) => {
          const selectedOrganisation = organisationsData.find((element) => element.emailDomain === value);
          onChange('organisation', selectedOrganisation);
        }}
        defaultValue={organisation && organisation.emailDomain}
        required
        hasError={!organisation}
        disabled={!!_id}
      />
    </Grid>
  );
};

interface INumberOfSeatsAndSeatsIncludedRendererProps {
  licenceData: Partial<ILicence>;
  onChange: (prop: string, value: any) => void;
}

export const NumberOfSeatsAndSeatsIncludedRenderer = (props: INumberOfSeatsAndSeatsIncludedRendererProps) => {
  const { licenceData, onChange } = props;

  return (
    <Grid item container direction="row" justifyContent="space-between">
      <NumberOfSeatsRenderer licenceData={licenceData} onChange={onChange} />
      <SeatsIncludedRenderer licenceData={licenceData} />
    </Grid>
  );
};

interface INumberOfSeatsRendererProps {
  licenceData: Partial<ILicence>;
  onChange: (prop: string, value: any) => void;
}

const NumberOfSeatsRenderer = (props: INumberOfSeatsRendererProps) => {
  const {
    licenceData: { type, numberOfSeats },
    onChange,
  } = props;

  return (
    <Grid item>
      <TextField
        disabled={type === LicenceType.USER}
        fullWidth
        required
        variant="filled"
        value={numberOfSeats || 0}
        error={!numberOfSeats || numberOfSeats < 0}
        label="Number of seats"
        onChange={(e) => onChange('numberOfSeats', +e.target.value)}
      />
    </Grid>
  );
};

interface ISeatsIncludedRendererProps {
  licenceData: Partial<ILicence>;
}

const SeatsIncludedRenderer = (props: ISeatsIncludedRendererProps) => {
  const {
    licenceData: { seatsIncluded },
  } = props;

  return (
    <Grid item>
      <TextField disabled fullWidth required variant="filled" value={seatsIncluded} label="Seats Included" />
    </Grid>
  );
};

interface IStatusRendererProps {
  licenceData: Partial<ILicence>;
  onChange: (prop: string, value: any) => void;
}

export const StatusRenderer = (props: IStatusRendererProps) => {
  const {
    licenceData: { status },
    onChange,
  } = props;

  return (
    <Grid item>
      <FormControl variant="filled" fullWidth>
        <InputLabel>Status</InputLabel>
        <Select label="Status" value={status} onChange={(event) => onChange('status', event.target.value)}>
          <MenuItem value="ACTIVE">ACTIVE</MenuItem>
          <MenuItem value="INACTIVE">INACTIVE</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
};

const getSeats = (numberOfSeats?: number, seatsIncluded?: number) => {
  const diff = (numberOfSeats || 0) - (seatsIncluded || 0);
  return diff < 0 ? 0 : diff;
};

interface ITotalCostRendererProps {
  licenceData: Partial<ILicence>;
}

export const TotalCostRenderer = (props: ITotalCostRendererProps) => {
  const {
    licenceData: { basePrice, pricePerSeat, seatsIncluded, numberOfSeats },
  } = props;

  const extraSeats = getSeats(numberOfSeats, seatsIncluded);

  const totalCost =
    ((basePrice && basePrice.value) || 0) + (extraSeats * ((pricePerSeat && pricePerSeat.value) || 0) || 0);
  return (
    <>
      <Grid item>
        <Typography variant="h3">Total cost -&nbsp;</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h4">{`${CURRENCY.GBP} ${totalCost}`}</Typography>
      </Grid>
    </>
  );
};

interface ILicenceTypeRendererProps {
  licenceData: Partial<ILicence>;
  onChange: (value: string) => void;
}

export const LicenceTypeRenderer = (props: ILicenceTypeRendererProps) => {
  const { licenceData, onChange } = props;
  return (
    <Grid item xs={5}>
      <SingleSelectField
        data={[LicenceType.USER, LicenceType.ORGANISATION]}
        label="Licence Type"
        onChange={onChange}
        defaultValue={licenceData.type}
        required
        hasError={false}
        disabled={!!licenceData._id}
      />
    </Grid>
  );
};
