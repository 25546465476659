/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import { gql } from '@apollo/client';

import { EntitlementTemplateFragment } from '../entitlement-template/fragments';

export const CREATE_LICENCE_TEMPLATE = gql`
  mutation createLicencetemplate($data: LicenceTemplateCreateInput!) {
    createLicencetemplate(data: $data) {
      _id
      name
      rights {
        ...EntitlementTemplateFields
      }
      basePrice {
        currency
        value
      }
      pricePerSeat {
        currency
        value
      }
      seatsIncluded
      numberOfSeats
      status
    }
  }
  ${EntitlementTemplateFragment.entitlementTemplateFields}
`;

export const UPDATE_LICENCE_TEMPLATE = gql`
  mutation updateLicencetemplate($data: LicenceTemplateUpdateInput!) {
    updateLicencetemplate(data: $data) {
      _id
      name
      status
      rights {
        ...EntitlementTemplateFields
      }
      basePrice {
        currency
        value
      }
    }
  }
  ${EntitlementTemplateFragment.entitlementTemplateFields}
`;
