/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';

import { EditButton } from '../../common/components/LinkButton';
import { getFormattedDate } from '../../common/utils';

export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'Licence Type',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => params.row.organisation && params.row.organisation.name,
    field: ' ',
    headerName: 'Organisation',
    type: 'string',
    flex: 1,
  },

  {
    renderCell: (params: GridCellParams) => params.row.startDate && getFormattedDate(params.row.startDate),
    field: 'startDate',
    headerName: 'Start Date',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => params.row.endDate && getFormattedDate(params.row.endDate),
    field: 'endDate',
    headerName: 'End Date',
    type: 'string',
    flex: 1,
  },

  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    flex: 1,
  },
  {
    field: 'edit',
    headerName: 'Actions',
    renderCell: (params: GridCellParams) => {
      return <EditButton path={`/licence/${params.row.type}/${params.row.id}`} title="Edit Licence" />;
    },
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
];
