/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React, { Component } from 'react';
import { IReport } from '@vamstar/fox-api-common/esm/reports';
import { DEFAULT_PAGE_SIZE, XGridTable } from '@vamstar/fox-frontend-common';
import { compose } from 'recompose';
import { log } from '@vamstar/fox-node-logger';

import { reportColumns } from './columns';
import { REPORTS } from './queries';
import { IUploadReportTableState } from './interface';
import { getArrayMap } from '../../common/utils';
import { XGridTableWrapper } from '../../common/components/XGridTableWrapper';
import { WithCommonPagination, withCommonPagination } from '../../common/utils/WithCommonPagination';

type HOCProps = WithCommonPagination;

class ReportTableView extends Component<HOCProps, IUploadReportTableState> {
  state: IUploadReportTableState = {
    reports: [],
    isLoading: false,
    currentPage: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    await this.fetchData();
    this.setState({ isLoading: false });
  }

  public fetchData = async () => {
    const { commonPaginationService } = this.props;
    const { pageSize, currentPage } = this.state;
    try {
      const skip = (currentPage - 1) * pageSize;
      const reportsResponse = await commonPaginationService.fetchPaginatedData<IReport>(REPORTS, skip, pageSize);
      if (reportsResponse !== null) {
        const { items, total } = reportsResponse.reports;
        this.setState({ reports: items, total, pageSize });
      }
    } catch (error) {
      log.error(JSON.stringify(error));
    }
  };

  onPageSizeChange = (newPageSize: number) => {
    this.setState({ pageSize: newPageSize, currentPage: 1 }, () => this.fetchData());
  };

  onPageChange = (page: number) => {
    this.setState({ currentPage: page }, () => this.fetchData());
  };

  public render() {
    const { isLoading, reports, total: totalCount, pageSize, currentPage } = this.state;

    return (
      <XGridTableWrapper heading="Reports" route="/report" buttonLabel="Add Reports">
        <XGridTable
          columns={reportColumns}
          rows={getArrayMap(reports)}
          count={totalCount}
          loading={isLoading}
          page={currentPage}
          rowsPerPage={pageSize}
          onPageSizeChange={this.onPageSizeChange}
          onPageChange={this.onPageChange}
        />
      </XGridTableWrapper>
    );
  }
}

const ReportTable: React.ComponentClass<any, IUploadReportTableState> = compose<HOCProps, any>(withCommonPagination)(
  ReportTableView,
);

export default ReportTable;
