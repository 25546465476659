/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { gql } from '@apollo/client';

import { EntitlementTemplateFragment } from './fragments';

export const ENTITLEMENT = gql`
  query entitlementtemplates($data: EntitlementTemplateListInput!) {
    entitlementtemplates(data: $data) {
      items {
        ...EntitlementTemplateFields
      }
      total
      hasMore
    }
  }
  ${EntitlementTemplateFragment.entitlementTemplateFields}
`;

export const ENTITLEMENT_BY_ID = gql`
  query entitlementtemplate($id: String!) {
    entitlementtemplate(id: $id) {
      ...EntitlementTemplateFields
    }
  }
  ${EntitlementTemplateFragment.entitlementTemplateFields}
`;

export const PRODUCTS = gql`
  query vamproducts($data: VamProductListInput!) {
    vamproducts(data: $data) {
      items {
        _id
        name
        productCode
        status
      }
      total
      hasMore
    }
  }
`;

export const ReportFragment = {
  reportFields: gql`
    fragment ReportFields on Report {
      _id
      title
      subTitle
      description
      s3File {
        key
        bucket
        originalUrl
      }
      thumbnail {
        key
        bucket
        originalUrl
      }
      status
      type
    }
  `,
};

export const REPORTS = gql`
  query reports($data: ReportListInput!) {
    reports(data: $data) {
      items {
        ...ReportFields
      }
      total
      hasMore
    }
  }
  ${ReportFragment.reportFields}
`;
