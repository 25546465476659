/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import { Button, Chip, InputAdornment, TextField, Autocomplete } from '@mui/material';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Close from '@mui/icons-material/Close';
import AddCircleOutlined from '@mui/icons-material/AddCircleOutlined';
import { ClassNameMap } from 'notistack';
import { FilterKey, ICountry } from '@vamstar/fox-api-common/esm/common/types';
import { countries as countryData } from '@vamstar/fox-api-common/esm/constants';
import { TextField as TextFieldCommon } from '@vamstar/fox-frontend-common/dist/main/components/forms/TextField';
import { getCountryNameByCode } from '@vamstar/fox-api-common/esm/utils';

import { entitlementTagModalStyles } from './styles';

const useStyles = makeStyles(() => entitlementTagModalStyles);

const getChipLabel = (filterKey: FilterKey | 'none', value: string) => {
  if (filterKey === 'entityIsoCountries') {
    return getCountryNameByCode(value);
  }
  return value;
};

/**
 * renders the chips below the text fields
 * */
const renderChips = (
  values: string[],
  onDeleteValue: (index: number, isAllowedValues: boolean) => void,
  isAllowedValues: boolean,
  classes: ClassNameMap<'chip' | 'textField'>,
  filterKey?: FilterKey | 'none',
) => {
  return values.map((value: string, index: number) => {
    return (
      <Chip
        size="small"
        key={value}
        icon={<Close />}
        label={getChipLabel(filterKey || 'none', value)}
        onDelete={() => onDeleteValue(index, isAllowedValues)}
        className={classes.chip}
      />
    );
  });
};

interface ITextFieldEntitlement {
  label: string;
  onAddValue: (currentValue: string, isAllowedValues: boolean) => void;
  isAllowedValues: boolean;
}

const TextFieldEntitlement = (props: ITextFieldEntitlement) => {
  const [currentValue, setCurrentValue] = React.useState('');
  const classes = useStyles();
  const { label, onAddValue, isAllowedValues } = props;
  return (
    <TextField
      autoFocus
      margin="dense"
      variant="outlined"
      id={label}
      label={label}
      type="text"
      fullWidth
      value={currentValue}
      helperText="Please click on the `add icon` to add new tags"
      className={classes.textField}
      onChange={(e) => {
        setCurrentValue(e.target.value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                onAddValue(currentValue, isAllowedValues);
                setCurrentValue('');
              }}
            >
              <AddCircleOutlined /> Add
            </Button>
          </InputAdornment>
        ),
      }}
    />
  );
};

interface IAddEditEntitlementValues {
  values: string[];
  label: string;
  onAddValue: (currentValue: string, isAllowedValues: boolean) => void;
  onDeleteValue: (index: number, isAllowedValues: boolean) => void;
  isAllowedValues: boolean;
  filterKey?: FilterKey | 'none';
}

/**
 * Add/Edit entitlements values component
 * */
const AddEditEntitlementValues = (props: IAddEditEntitlementValues) => {
  const { values, filterKey, label, onAddValue, onDeleteValue, isAllowedValues } = props;
  const classes = useStyles();

  return (
    <>
      {filterKey === 'entityIsoCountries' ? (
        <>
          <Autocomplete<ICountry>
            getOptionLabel={(option) => {
              const { name } = option;
              return `${name}`;
            }}
            onChange={(_event, value, reason) => {
              if (reason === 'selectOption' && value) {
                _event.preventDefault();
                onAddValue(value.code, isAllowedValues);
              }
            }}
            options={countryData}
            renderInput={(params) => (
              <TextFieldCommon
                {...params}
                label={label}
                variant="outlined"
                className={classes.textField}
                margin="dense"
                helperText="Select from dropdown to add"
              />
            )}
          />
        </>
      ) : (
        <TextFieldEntitlement label={label} onAddValue={onAddValue} isAllowedValues={isAllowedValues} />
      )}
      {renderChips(values, onDeleteValue, isAllowedValues, classes, filterKey)}
    </>
  );
};

export default AddEditEntitlementValues;
