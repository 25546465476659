/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { IEmS3File } from '@vamstar/fox-api-common/esm/file-upload';

interface IFileTableProps {
  file: IEmS3File;
  title: string;
}

type HOCProps = WithTranslation & IFileTableProps;

class FileTableView extends Component<HOCProps> {
  renderFileInfo = () => {
    const { file } = this.props;
    return (
      <TableRow>
        <TableCell component="th" scope="row" align="center">
          {file.originalUrl}
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {file.key}
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {file.bucket}
        </TableCell>
      </TableRow>
    );
  };

  public render() {
    const { title } = this.props;
    return (
      <TableContainer component={Paper}>
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Original Url</TableCell>
              <TableCell align="center">Key</TableCell>
              <TableCell align="center">Bucket</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderFileInfo()}</TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export const FileTable: React.ComponentClass<IFileTableProps> = compose<HOCProps, IFileTableProps>(withTranslation())(
  FileTableView,
);
