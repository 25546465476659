/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React from 'react';
import clsx from 'clsx';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Drawer as MuiDrawer, Divider, List, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';

import { dashboardRoutes } from './routes';
import { styles } from './styles';

export interface IDrawerProps {
  closeDrawer: () => void;
  isDrawerOpen: boolean;
}

export const Drawer: React.FC<IDrawerProps> = ({ closeDrawer, isDrawerOpen }: IDrawerProps) => {
  const classes = makeStyles(styles)();

  return (
    <MuiDrawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose),
      }}
      open={isDrawerOpen}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={closeDrawer} size="large">
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {dashboardRoutes.map((item) => (
          <Link
            className={classes.link}
            key={item.path as string}
            to={item.path as string}
            style={{ display: item.inSideBar ? '' : 'none' }}
          >
            <Tooltip title={item.title || ''}>
              <ListItem key={item.path as string} button>
                {item.icon && <ListItemIcon>{item.icon as React.ReactElement}</ListItemIcon>}
                <ListItemText primary={item.title} />
              </ListItem>
            </Tooltip>
          </Link>
        ))}
      </List>
    </MuiDrawer>
  );
};
