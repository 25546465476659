/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { ResetPassword as CommonPasswordReset, IApolloClient } from '@vamstar/fox-frontend-common';
import { IResetPasswordFormData } from '@vamstar/fox-frontend-common/dist/main/auth/ResetPassword';
import React, { Component } from 'react';
import { WithApolloClient } from '@apollo/client/react/hoc';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { withSnackbar, ProviderContext } from 'notistack';
import { log } from '@vamstar/fox-node-logger';

import { FORGOT_PASSWORD, RESET_PASSWORD } from './mutations';
import { authStyles } from './styles';
import { Logo } from './Logo';

interface IPasswordResetState {
  isLoading?: boolean;
}

type HOCProps = WithTranslation &
  RouteComponentProps<{ resetToken: string }> &
  WithApolloClient<IApolloClient> &
  ProviderContext &
  WithStyles<any>;

class PasswordResetView extends Component<HOCProps, IPasswordResetState> {
  public state: IPasswordResetState = {
    isLoading: false,
  };

  public async componentDidMount() {
    window.location.replace(`${process.env.REACT_APP_USER_APP_URL}/reset-password`);
  }

  public sendForgotPasswordEmail = async (formData: IResetPasswordFormData) => {
    const { client, history } = this.props;
    this.setState({ isLoading: true });

    try {
      await client.mutate({
        mutation: FORGOT_PASSWORD,
        variables: formData,
        fetchPolicy: 'no-cache',
      });

      history.push('/');
    } catch (error) {
      log.error(`${error}`);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  public resetPassword = async (password: string) => {
    const { client, history } = this.props;
    this.setState({ isLoading: true });

    try {
      await client.mutate({
        mutation: RESET_PASSWORD,
        variables: { password },
        fetchPolicy: 'no-cache',
      });

      history.push('/');
    } catch (err) {
      log.error(`${err}`);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  public render() {
    const { isLoading } = this.state;
    const { t, match, history, classes } = this.props;

    return (
      <div className={classes.container}>
        <Logo />
        <div>
          <CommonPasswordReset
            confirmCode={match.params.resetToken}
            onPasswordSubmit={this.resetPassword}
            onLoginClick={() => history.push('/login')}
            onPasswordReset={this.sendForgotPasswordEmail}
            resetLabel={t('labels:reset')}
            signinLabel={t('labels:signin')}
            emailLabel={t('labels:email')}
            helperText="Enter the email address you registered with"
            isLoading={isLoading}
            xs={5}
          />
        </div>
      </div>
    );
  }
}

export const PasswordReset: React.ComponentClass<any, IPasswordResetState> = compose<HOCProps, any>(
  withTranslation(['common', 'labels']),
  withSnackbar,
  withStyles(authStyles),
)(PasswordResetView);
