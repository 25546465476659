/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { IEmUser } from '@vamstar/fox-api-common/esm/user/types';
import { compose } from 'recompose';
import { withSnackbar, ProviderContext } from 'notistack';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { IApolloClient } from '@vamstar/fox-frontend-common';
import { log } from '@vamstar/fox-node-logger';

import { AutocompleteField } from '../../../common/components/AutocompleteField';
import { withCommonPagination, WithCommonPagination } from '../../../common/utils/WithCommonPagination';
import { CommonPaginationService } from '../../../common/utils/common-pagination.service';
import { SEARCH_ACTIVE_USERS } from '../../users/queries';
import { ADD_USER_ORGANISATION } from '../mutations';
import { removeProp } from '../../../util/object';
import { MIN_SEARCH_STRING_LENGTH, DEFAULT_TAKE } from '../../../constants/pagination';

interface IOrganisationAddUserModalProps {
  open: boolean;
  handleClose: () => void;
  selectedUsers: IEmUser[];
  organisationId: string;
  addNewUser: (user: IEmUser) => void;
}

interface IOrganisationAddUserModalState {
  users: IEmUser[];
  selectedUser: IEmUser | undefined;
}

type HOCProps = IOrganisationAddUserModalProps &
  WithCommonPagination &
  WithApolloClient<IApolloClient> &
  ProviderContext;

class OrganisationAddUserFormModalView extends React.Component<HOCProps, IOrganisationAddUserModalState> {
  state: IOrganisationAddUserModalState = {
    users: [],
    selectedUser: undefined,
  };

  componentDidMount() {
    this.getUsers();
  }

  // TODO: Remove this later and convert the select field to a text field
  getUsers = async (searchQuery: string = '') => {
    const { client } = this.props;
    const response = await client.query({
      query: SEARCH_ACTIVE_USERS,
      variables: {
        searchQuery,
        skip: 0,
        take: DEFAULT_TAKE,
      },
    });

    if (response !== null && response.data && response.data.searchActiveUsers) {
      const { items } = response.data.searchActiveUsers;
      const emUsers: IEmUser[] = CommonPaginationService.toEmUsers(items);
      if (emUsers.length > 0) this.setState({ users: emUsers });
    }
  };

  onChange = (email: string) => {
    const { users } = this.state;
    const foundUser = users.find((user) => user.email === email);
    this.setState({ selectedUser: foundUser });
  };

  onInputChange = (value: string) => {
    if (value.length > MIN_SEARCH_STRING_LENGTH) {
      this.getUsers(value);
    }
  };

  onAddUser = async () => {
    try {
      const { selectedUser } = this.state;
      const { enqueueSnackbar } = this.props;
      if (selectedUser) {
        const { organisationId, client, handleClose, addNewUser } = this.props;
        const emUser = removeProp(selectedUser, '__typename');
        const { data } = await client.mutate({
          mutation: ADD_USER_ORGANISATION,
          variables: {
            userId: emUser.user,
            organisationId,
          },
        });
        const { addUserToOrganisation } = data;
        addNewUser(addUserToOrganisation);
        enqueueSnackbar('User added to organisation', { variant: 'success' });
        handleClose();
      } else {
        enqueueSnackbar('Select user to add to an organisation', { variant: 'warning' });
      }
    } catch (error) {
      log.error(`Error while adding user ${error}`);
    }
  };

  render() {
    const { open, handleClose } = this.props;
    const { users, selectedUser } = this.state;
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Select user to add to an Organisation</DialogTitle>
        <DialogContent>
          <AutocompleteField
            data={CommonPaginationService.getUserEmails(users)}
            label="Select User"
            onChange={this.onChange}
            onInputChange={this.onInputChange}
            required
            hasError={false}
            defaultValue={selectedUser && selectedUser.email}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={this.onAddUser} disabled={!selectedUser} variant="contained" color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const OrganisationAddUserFormModal: React.ComponentClass<
  IOrganisationAddUserModalProps,
  IOrganisationAddUserModalState
> = compose<HOCProps, IOrganisationAddUserModalProps>(
  withApollo,
  withSnackbar,
  withCommonPagination,
)(OrganisationAddUserFormModalView);
