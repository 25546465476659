/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';

import { isSuperAdmin } from '../../common/utils';
import { EditButton, ViewButton } from '../../common/components/LinkButton';

export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    type: 'string',
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'Type',
    type: 'string',
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Description',
    type: 'string',
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'string',
    flex: 1,
  },
  {
    field: 'edit',
    headerName: 'Actions',
    renderCell: (params: GridCellParams) => {
      if (isSuperAdmin()) {
        return <EditButton path={`/entitlement-template/${params.row.id}`} title="Edit Entitlement Template" />;
      }
      return <ViewButton path={`/entitlement-template/${params.row.id}`} title="View Entitlement Template" />;
    },
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
];
