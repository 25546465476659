/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { Role, UserStatus } from '@vamstar/fox-api-common/esm/user/types';

export const status = Object.keys(UserStatus).map((userStatus) => ({ name: UserStatus[userStatus] }));

export const userRoles = Object.keys(Role).map((role) => ({ name: Role[role] }));

export enum CURRENCY {
  GBP = 'GBP',
}

export const PASSWORD_PLACEHOLDER = 'Password Placeholder';
export const NA = 'NA';
