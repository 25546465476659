/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React from 'react';
import { Select, MenuItem, Checkbox, ListItemText, SelectChangeEvent } from '@mui/material';

interface ISelectFieldProps {
  data: string[];
  onChange: (newValue: any) => void;
  value: any;
}

interface ISelectFieldState {
  selectFieldData: string[];
}

export class MultiEnumSelectField extends React.Component<ISelectFieldProps> {
  public state: ISelectFieldState = {
    selectFieldData: this.props.value || [],
  };

  handleChange = (event: SelectChangeEvent<string[]>) => {
    const { data, onChange } = this.props;
    const eventData = event.target.value;
    this.setState({ selectFieldData: eventData });
    const selectedData = data.filter((item) => eventData.includes(item));

    onChange(selectedData);
  };

  render() {
    const { data, value } = this.props;
    const { selectFieldData } = this.state;

    let initialData: string[] = [];
    if (value) {
      initialData = value;
    }

    return (
      <Select
        color="primary"
        variant="filled"
        multiple
        value={initialData}
        onChange={this.handleChange}
        renderValue={(selected) => (selected as string[]).join(', ')}
      >
        {data.map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox color="primary" checked={selectFieldData.indexOf(item) > -1} />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    );
  }
}
