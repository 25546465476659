/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React, { Component } from 'react';
import { IEntitlementTemplate } from '@vamstar/fox-api-common/esm/entitlement-template/types';
import { DEFAULT_PAGE_SIZE, XGridTable } from '@vamstar/fox-frontend-common';
import { compose } from 'recompose';
import { log } from '@vamstar/fox-node-logger';

import { columns } from './columns';
import { ENTITLEMENT } from './queries';
import { XGridTableWrapper } from '../../common/components/XGridTableWrapper';
import { withCommonPagination, WithCommonPagination } from '../../common/utils/WithCommonPagination';
import { XGridSearch } from '../../common/components/XGridSearch';

interface IEntitlementTemplatesTableState {
  entitlementData: IEntitlementTemplate[];
  isLoading: boolean;
  totalCount: number;
  currentPage: number;
  pageSize: number;
  searchText: string;
}

type HOCProps = WithCommonPagination;

class EntitlementTemplatesTableView extends Component<HOCProps> {
  state: IEntitlementTemplatesTableState = {
    entitlementData: [],
    isLoading: false,
    currentPage: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
    searchText: '',
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ isLoading: true });

    const { commonPaginationService } = this.props;
    const { pageSize, currentPage, searchText } = this.state;
    try {
      const skip = (currentPage - 1) * pageSize;
      const entitlementResponse = await commonPaginationService.fetchPaginatedData<IEntitlementTemplate>(
        ENTITLEMENT,
        skip,
        pageSize,
        searchText,
      );
      if (entitlementResponse !== null) {
        const { items, total } = entitlementResponse.entitlementtemplates;
        this.setState({ entitlementData: items, totalCount: total, pageSize });
      }
    } catch (error) {
      log.error(JSON.stringify(error));
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onPageSizeChange = (newPageSize: number) => {
    this.setState({ pageSize: newPageSize, currentPage: 0 }, () => this.fetchData());
  };

  onPageChange = (page: number) => {
    this.setState({ currentPage: page }, () => this.fetchData());
  };

  onSearchChange = (searchText: string) => {
    this.setState({ searchText });
  };

  render() {
    const { isLoading, entitlementData, totalCount, pageSize, currentPage, searchText } = this.state;
    return (
      <XGridTableWrapper
        heading="Entitlement Templates"
        route="/entitlement-template"
        buttonLabel="Add Entitlement Template"
        checkSuperAdmin
      >
        <XGridSearch
          value={searchText}
          onSearchSubmit={() => this.fetchData()}
          onSearchChange={(val) => this.onSearchChange(val)}
        />
        <XGridTable
          columns={columns}
          rows={entitlementData}
          count={totalCount}
          loading={isLoading}
          onPageChange={this.onPageChange}
          onPageSizeChange={this.onPageSizeChange}
          page={currentPage}
          rowsPerPage={pageSize}
        />
      </XGridTableWrapper>
    );
  }
}

const EntitlementTemplatesTable: React.ComponentClass<any, IEntitlementTemplatesTableState> = compose<HOCProps, any>(
  withCommonPagination,
)(EntitlementTemplatesTableView);

export default EntitlementTemplatesTable;
