/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React from 'react';
import { Button, Grid, TextField } from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { IClaimSupplierProfile } from '@vamstar/fox-api-common/esm/claim-supplier-profile/types';

import { ISuppliersProps, IRenderSupplierInviteFieldsProps, ISupplierFieldProps } from '../interfaces';
import { getValidationProps, isEmailValid } from '../invite-suppliers-utils';

const isAddButtonDisabled = (supplier: Partial<IClaimSupplierProfile>) => {
  return !(
    supplier.firstname &&
    supplier.lastname &&
    supplier.companyname &&
    supplier.email &&
    isEmailValid(supplier.email)
  );
};

export const Suppliers = (props: ISuppliersProps) => {
  const { removeSupplier, updateSupplierList, onChange, supplierList } = props;
  return (
    <>
      {supplierList.map((supplier, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid container direction="column" spacing={4} style={{ marginBottom: '1rem' }} key={index}>
          <Grid container item justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                disabled={supplierList.length === 1}
                variant="contained"
                color="primary"
                endIcon={<DeleteRoundedIcon />}
                onClick={() => removeSupplier(index)}
              >
                Remove
              </Button>
            </Grid>
            {(index === 0 && supplierList.length === 0) || index === supplierList.length - 1 ? (
              <Grid item>
                <Button
                  disabled={isAddButtonDisabled(supplier)}
                  variant="contained"
                  color="primary"
                  onClick={updateSupplierList}
                >
                  Add
                </Button>
              </Grid>
            ) : null}
          </Grid>
          <RenderSupplierInviteFields onChange={onChange} supplier={supplier} index={index} />
        </Grid>
      ))}
    </>
  );
};

const RenderSupplierInviteFields = (props: IRenderSupplierInviteFieldsProps) => {
  const { onChange, supplier, index } = props;
  return (
    <>
      <Grid item>
        <SupplierField
          onChange={(e) => onChange(e, index)}
          fieldName="companyname"
          fieldValue={supplier.companyname}
          label="Company Name"
        />
      </Grid>
      <Grid container item spacing={1}>
        <Grid item xs={6}>
          <SupplierField
            onChange={(e) => onChange(e, index)}
            fieldName="firstname"
            fieldValue={supplier.firstname}
            label="First Name"
          />
        </Grid>
        <Grid item xs={6}>
          <SupplierField
            onChange={(e) => onChange(e, index)}
            fieldName="lastname"
            fieldValue={supplier.lastname}
            label="Last Name"
          />
        </Grid>
      </Grid>
      <Grid item>
        <SupplierField
          onChange={(e) => onChange(e, index)}
          fieldName="email"
          fieldValue={supplier.email}
          label="Email Id"
        />
      </Grid>
    </>
  );
};

const SupplierField = (props: ISupplierFieldProps) => {
  const { fieldName, fieldValue, onChange, label } = props;
  const { error, helperText } = getValidationProps(fieldName, fieldValue);

  return (
    <TextField
      required
      error={error}
      helperText={helperText}
      fullWidth
      name={fieldName}
      value={fieldValue || ''}
      variant="filled"
      label={label}
      onChange={onChange}
    />
  );
};
