/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

export const STORAGE_KEYS = {
  AUTH_DATA: 'auth_data',
};
