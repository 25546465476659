/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { IClaimSupplierProfile } from '@vamstar/fox-api-common/esm/claim-supplier-profile/types';
import { FormTemplate, IApolloClient } from '@vamstar/fox-frontend-common';
import { log } from '@vamstar/fox-node-logger';
import { withSnackbar, ProviderContext } from 'notistack';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { FetchResult } from '@apollo/client';

import { withCommonPagination } from '../../../common/utils/WithCommonPagination';
import { MatchParams } from '../../licence/constants';
import { InviteSuppliersFormViewState } from '../interfaces';
import { isEmailValid, isFormDisabled } from '../invite-suppliers-utils';
import { SEND_MAIL_INVITES } from '../mutations';
import { Suppliers } from './Suppliers';
import DuplicateSupplierEmailAlert from './DuplicateSupplierEmailAlert';

type HOCProps = WithApolloClient<IApolloClient> & ProviderContext & WithTranslation & RouteComponentProps<MatchParams>;

class InviteSuppliersFormView extends React.PureComponent<HOCProps> {
  public state: InviteSuppliersFormViewState = {
    supplierList: [
      {
        companyname: undefined,
        email: undefined,
        firstname: undefined,
        lastname: undefined,
      },
    ],
    supplierValidity: { 0: false },
    modalDescription: {},
    modalState: false,
  };

  handleDialogClose = () => {
    const { history } = this.props;
    this.setState({ modalState: false });
    history.push({
      pathname: '/invite-suppliers',
    });
  };

  showInvitedSupplierModal = (response: FetchResult<any, Record<string, any>, Record<string, any>>) => {
    const { inviteNotSentList, inviteSentList } = response.data.inviteSuppliers;
    const inviteInfo = {
      emailInviteNotSentList: inviteNotSentList,
      emailInviteSentList: inviteSentList,
    };
    this.setState({ modalState: true, modalDescription: inviteInfo });
  };

  sendMailInvite = async () => {
    const { client, enqueueSnackbar } = this.props;
    const { supplierList } = this.state;

    try {
      const response = await client.mutate({
        mutation: SEND_MAIL_INVITES,
        variables: {
          items: supplierList,
        },
      });
      if (response) {
        this.showInvitedSupplierModal(response);
      }
    } catch (error) {
      log.debug(`[sendMailInvite] Error in sending email: ${error}`);
      enqueueSnackbar('Something went wrong, please try again', { variant: 'error', persist: true });
    }
  };

  removeSupplier = (index: number) => {
    const { supplierList, supplierValidity } = this.state;
    const currentList = [...supplierList];
    currentList.splice(index, 1);
    delete supplierValidity[index];
    this.setState({ supplierList: currentList, supplierValidity });
  };

  emailAlreadyExists = (email: string | undefined, index: number) => {
    const { supplierList } = this.state;
    return supplierList.some((supplier, i) => supplier.email === email && i !== index);
  };

  updateSupplierList = () => {
    const { supplierList, supplierValidity } = this.state;

    supplierValidity[supplierList.length] = false;
    this.setState({
      supplierList: [
        ...supplierList,
        { firstname: undefined, lastname: undefined, email: undefined, companyname: undefined },
      ],
      supplierValidity,
    });
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { supplierValidity } = this.state;
    const fieldValue = e.target.value as string;
    const key = e.target.name;
    const { supplierList } = this.state;
    const updatedList = [...supplierList];
    updatedList[index][key] = fieldValue || '';

    supplierValidity[index] = this.isSupplierValid(updatedList[index]);
    if (key === 'email') {
      supplierValidity[index] = supplierValidity[index] && !this.emailAlreadyExists(updatedList[index].email, index);
    }
    this.setState({ supplierList: updatedList, supplierValidity });
  };

  isSupplierValid = (supplier: Partial<IClaimSupplierProfile>) => {
    return (
      (supplier.companyname &&
        supplier.firstname &&
        supplier.lastname &&
        supplier.email &&
        isEmailValid(supplier.email)) ||
      false
    );
  };

  renderSuppliers = () => {
    const { supplierList } = this.state;

    return (
      <Suppliers
        supplierList={supplierList}
        onChange={this.onChange}
        removeSupplier={this.removeSupplier}
        updateSupplierList={this.updateSupplierList}
      />
    );
  };

  render() {
    const { modalDescription, modalState } = this.state;
    return (
      <>
        <DuplicateSupplierEmailAlert
          description={modalDescription}
          modalState={modalState}
          handleClose={this.handleDialogClose}
        />
        <FormTemplate
          isFormDisabled={isFormDisabled(this.state)}
          isLoading={false}
          onSubmit={this.sendMailInvite}
          formLabel="Invitation"
        >
          {this.renderSuppliers()}
        </FormTemplate>
      </>
    );
  }
}

const InviteSuppliersForm: React.ComponentClass<any> = compose<HOCProps, any>(
  withCommonPagination,
  withApollo,
  withRouter,
  withSnackbar,
)(InviteSuppliersFormView);

export default InviteSuppliersForm;
