/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { ApolloClient, DocumentNode, NormalizedCacheObject } from '@apollo/client';
import { IPaginatedResponse } from '@vamstar/fox-api-common/esm/common/types';
import { IOrganisation } from '@vamstar/fox-api-common/esm/organisation/types';
import { IEmUser, IUser } from '@vamstar/fox-api-common/esm/user/types';
import { log } from '@vamstar/fox-node-logger';

import { DEFAULT_TAKE } from '../../constants/pagination';

interface IGenericPaginatedResponse<T> {
  [key: string]: IPaginatedResponse<T>;
}

export class CommonPaginationService {
  private __apolloClient!: ApolloClient<NormalizedCacheObject>;

  /**
   * Common paginated queries that can be used on all models in the system
   * @param skip - pageSize * currentPage will be the skip
   * @returns embedded users
   */
  public fetchPaginatedData = async <T>(
    query: DocumentNode,
    skip: number = 0,
    take: number = DEFAULT_TAKE,
    searchQuery: string = '',
    filter: any = null,
  ): Promise<IGenericPaginatedResponse<T> | null> => {
    try {
      const responseForUsers = await this.__apolloClient.query({
        query,
        variables: {
          data: {
            skip,
            take,
            searchQuery,
            filter,
          },
        },
        fetchPolicy: 'network-only',
      });
      return responseForUsers.data;
    } catch (error) {
      log.error(`${error}`);
    }
    return null;
  };

  /**
   * Converts all users to emUsers
   * @param users - all the queries users
   * @returns embedded users
   */
  public static toEmUsers = (users: IUser[]): IEmUser[] => {
    return users.map((user) => {
      const { basicInfo } = user;
      return {
        user: user._id,
        email: basicInfo.email,
        roles: basicInfo.roles,
        firstName: basicInfo.firstName,
        lastName: basicInfo.lastName,
      };
    });
  };

  /**
   * Gets all the user emails
   * @param users - All embedded users queried
   * @returns All user emails to be displayed in the select field
   */
  public static getUserEmails = (users: IEmUser[]): string[] => {
    return users.map((user) => user.email);
  };

  /**
   * Gets all the organisation names
   * @param users - All organisations queried
   * @returns All organisation names to be displayed in the select field
   */
  public static getOrganisationNames = (organisations: IOrganisation[]): string[] => {
    return organisations.map((org) => org.name);
  };

  /**
   * ====================
   * Getters & Setters
   * ====================
   * */
  set apolloClient(client: ApolloClient<NormalizedCacheObject>) {
    this.__apolloClient = client;
  }
}
