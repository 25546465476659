import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation login($email: String!, $password: String!, $role: Role!) {
    login(email: $email, password: $password, role: $role) {
      originalUser {
        token
        user {
          _id
        }
        basicUserInfo {
          email
          firstName
          lastName
          userStatus
          roles
        }
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const VERIFY_RESET_PASSWORD_TOKEN = gql`
  mutation verifyResetPasswordToken($hashToken: String!) {
    verifyResetPasswordToken(hashToken: $hashToken)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`;
