/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppApolloProvider, AppRoot } from '@vamstar/fox-frontend-common';
import { useTranslation } from 'react-i18next';

import { cache, request } from './util/apollo';
import { clearAuthData } from './util/storage';
import { RootNavigator } from './navigation/RootNavigator';

const App = () => {
  const { t } = useTranslation('errors');
  return (
    <AppRoot>
      <AppApolloProvider cache={cache} request={request} clearAuthData={clearAuthData} t={t}>
        <BrowserRouter>
          <RootNavigator />
        </BrowserRouter>
      </AppApolloProvider>
    </AppRoot>
  );
};

export default App;
