/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import i18next, { ResourceLanguage, Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

const namespaces = ['common', 'errors', 'labels'];
export const languageCodes = ['en', 'es'];

const getNamespacesForLanguage = (languageCode: string): ResourceLanguage => {
  const languageNamespaces: ResourceLanguage = {};
  namespaces.forEach((namespace) => {
    // eslint-disable-next-line
    languageNamespaces[namespace] = require(`../../locale/${languageCode}/${namespace}.json`);
  });

  return languageNamespaces;
};

const getResources = () => {
  const resources: Resource = {};

  languageCodes.forEach((languageCode) => {
    resources[languageCode] = getNamespacesForLanguage(languageCode);
  });
  return resources;
};

i18next.use(initReactI18next).init({
  interpolation: {
    escapeValue: false,
  },
  lng: 'en',
  fallbackLng: 'en',
  defaultNS: 'common',
  ns: namespaces,
  debug: true,
  resources: getResources(),
});

export default i18next;
