/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import React, { ComponentType } from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { IconButton } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { IApolloClient } from '@vamstar/fox-frontend-common';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { log } from '@vamstar/fox-node-logger';

import { getAuthData, clearStorage } from '../../util/storage';
import { LOGOUT_USER } from './queries';

type HOCProps = RouteComponentProps & WithApolloClient<IApolloClient>;

export class LogoutView extends React.Component<HOCProps> {
  public logout = async () => {
    const { client, history } = this.props;
    const authData = getAuthData();

    if (authData && authData.token) {
      const { token } = authData;
      try {
        await client.mutate({
          mutation: LOGOUT_USER,
          variables: { token },
        });

        clearStorage();
        history.push('/login');
      } catch (error) {
        log.error(`${error}`);
      }
    } else {
      history.push('/login');
    }
  };

  render() {
    return (
      <>
        <IconButton color="inherit" onClick={() => this.logout()} size="large">
          <ExitToAppIcon htmlColor="white" />
        </IconButton>
      </>
    );
  }
}

export const Logout = compose(withApollo, withRouter)(LogoutView as ComponentType<unknown>);
