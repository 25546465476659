import React from 'react';
import { TextField } from '@mui/material';
import { ILicenceTemplate } from '@vamstar/fox-api-common/esm/licence-template/types';
import { ILicence } from '@vamstar/fox-api-common/esm/licence/types';

import { getSeatsIncluded } from '../utils';

interface IBasePriceAndPricePerSeatProps {
  data: Partial<ILicenceTemplate> | Partial<ILicence>;
  onChange: (prop: string, value: any) => void;
  disabled?: boolean;
}

export const BasePrice = (props: IBasePriceAndPricePerSeatProps) => {
  const {
    data: { basePrice, pricePerSeat },
    onChange,
    disabled,
  } = props;

  const parsedBasePrice = basePrice && basePrice.value ? basePrice.value.toString() : '0';

  return (
    <TextField
      disabled={disabled}
      fullWidth
      required
      variant="filled"
      value={parsedBasePrice}
      error={!basePrice || Number.isNaN(basePrice.value)}
      label="Base Price"
      onChange={(e) => {
        const seatsIncluded = getSeatsIncluded(+e.target.value, pricePerSeat && pricePerSeat.value);
        onChange('basePrice', {
          ...basePrice,
          value: e.target.value,
        });
        onChange('seatsIncluded', seatsIncluded);
        onChange('numberOfSeats', seatsIncluded);
      }}
    />
  );
};

export const PricePerSeat = (props: IBasePriceAndPricePerSeatProps) => {
  const {
    data: { basePrice, pricePerSeat },
    disabled,
    onChange,
  } = props;

  const parsedPricePerSeat = pricePerSeat && pricePerSeat.value ? pricePerSeat.value.toString() : '0';

  return (
    <TextField
      disabled={disabled}
      fullWidth
      required
      variant="filled"
      value={parsedPricePerSeat}
      error={!pricePerSeat || Number.isNaN(parsedPricePerSeat)}
      label="Price/Seat"
      onChange={(e) => {
        const seatsIncluded = getSeatsIncluded(basePrice && basePrice.value, +e.target.value);
        onChange('pricePerSeat', {
          ...pricePerSeat,
          value: e.target.value,
        });
        onChange('seatsIncluded', seatsIncluded);
        onChange('numberOfSeats', seatsIncluded);
      }}
    />
  );
};
