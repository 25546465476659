/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';

import { EditButton } from '../../common/components/LinkButton';

export const columns: GridColDef[] = [
  {
    renderCell: (params: GridCellParams) => params.row.basicInfo.firstName,
    field: 'basicInfo.firstName',
    headerName: 'First Name',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => params.row.basicInfo.lastName,
    field: 'basicInfo.lastName',
    headerName: 'Last Name',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => params.row.basicInfo.email,
    field: 'basicInfo.email',
    headerName: 'Email',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => params.row.basicInfo.roles.join(', '),
    field: 'basicInfo.roles',
    headerName: 'Roles',
    type: 'string',
    flex: 1,
  },
  {
    renderCell: (params: GridCellParams) => params.row.basicInfo.userStatus.join(', '),
    field: 'basicInfo.userStatus',
    headerName: 'User Status',
    type: 'string',
    flex: 1,
  },
  {
    field: 'edit',
    headerName: 'Actions',
    renderCell: (params: GridCellParams) => {
      return <EditButton path={`/user/${params.row.id}`} title="Edit User" />;
    },
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
];
