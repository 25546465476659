/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { GridColumns } from '@mui/x-data-grid-pro';

export const organisationMetricsColumns: GridColumns = [
  {
    headerName: 'Keyword',
    field: 'keyword',
    flex: 1,
  },
  {
    headerName: 'Tag type',
    field: 'tagType',
    flex: 1,
  },
  {
    headerName: 'Dashboard Tenders',
    field: 'dashboardCount',
    flex: 1,
  },
  {
    headerName: 'Open Tenders',
    field: 'openTendersCount',
    flex: 1,
  },
  {
    headerName: 'Total Tenders',
    field: 'totalCount',
    flex: 1,
  },
];
