/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { withSnackbar, ProviderContext } from 'notistack';
import { ObjectId } from 'bson';
import { IEntitlementPath } from '@vamstar/fox-api-common/esm/entitlement-template/types/entitlement-path';

import AddEditEntitlementValues from './AddEditEntitlementValues';

interface IEntitlementPathModalProps {
  entitlementId?: string | ObjectId;
  onChange: (newValue: any) => void;
  entitledPath: IEntitlementPath;
  allowEditAlways?: boolean;
}

interface IEntitlementPathModalState {
  open: boolean;
  currentEntitlementPath: IEntitlementPath;
}

type HOCProps = ProviderContext & WithTranslation & IEntitlementPathModalProps;

class EntitlementPathModalView extends React.Component<HOCProps, IEntitlementPathModalState> {
  state: IEntitlementPathModalState = {
    open: false,
    currentEntitlementPath: this.props.entitledPath,
  };

  handleModal = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  /**
   * checks if the form has got any errors
   * validation - If both allowed and excluded values are not present then the validation fails
   * */
  hasError = () => {
    const { currentEntitlementPath } = this.state;

    return currentEntitlementPath && currentEntitlementPath.allowedValues.length === 0;
  };

  /**
   * Checks if value is already part of any of the values. Prevents duplicates
   * @param currentValue - current value user's trying to add
   * */
  checkIfAlreadyPresent = (currentValue: string): boolean => {
    const { currentEntitlementPath } = this.state;
    const { enqueueSnackbar } = this.props;
    if (currentEntitlementPath) {
      const { allowedValues, excludedValues } = currentEntitlementPath;
      if (allowedValues.includes(currentValue) || excludedValues.includes(currentValue)) {
        enqueueSnackbar('Cannot add duplicates. Please try a different name!', { variant: 'warning' });
        return false;
      }
    }
    return true;
  };

  /**
   * adds the value to either of allowed values or excluded values
   * @param currentValue - value user entered
   * @param isAllowedValues - flag to determine whether allowed or exclude values
   * */
  onAddValue = (currentValue: string, isAllowedValues: boolean) => {
    const { currentEntitlementPath } = this.state;
    if (currentEntitlementPath && currentValue && this.checkIfAlreadyPresent(currentValue)) {
      if (isAllowedValues) {
        currentEntitlementPath.allowedValues.push(currentValue);
      }
      if (!isAllowedValues) {
        currentEntitlementPath.excludedValues.push(currentValue);
      }
      this.setState({ currentEntitlementPath });
    }
  };

  /**
   * deletes the value from either of allowed values or excluded values
   * @param index - index of the value to delete
   * @param isAllowedValues - flag to determine whether allowed or exclude values
   * */
  onDeleteValue = (index: number, isAllowedValues: boolean) => {
    const { currentEntitlementPath } = this.state;
    if (currentEntitlementPath) {
      if (isAllowedValues) {
        currentEntitlementPath.allowedValues.splice(index, 1);
      }
      if (!isAllowedValues) {
        currentEntitlementPath.excludedValues.splice(index, 1);
      }
      this.setState({ currentEntitlementPath });
    }
  };

  /**
   * First chacks if path is already part of users entitled paths else adds the new path
   * */
  onSaveEntitlementPaths = () => {
    const { currentEntitlementPath } = this.state;
    const { onChange } = this.props;
    onChange(currentEntitlementPath);
    this.handleModal();
  };

  /**
   * renders the dialog action buttons. If form has any error the save button gets disabled
   * @param hasError - If form has got any error
   * */
  renderDialogActions = () => {
    return (
      <DialogActions>
        <Button onClick={this.handleModal} color="primary">
          Cancel
        </Button>
        <Button onClick={this.onSaveEntitlementPaths} color="primary" disabled={this.hasError()}>
          Save
        </Button>
      </DialogActions>
    );
  };

  render() {
    const { open, currentEntitlementPath } = this.state;
    const { entitlementId, allowEditAlways } = this.props;
    return (
      <>
        <Button
          disabled={!!entitlementId && !allowEditAlways}
          variant="contained"
          color="primary"
          onClick={this.handleModal}
        >
          Add / Edit Paths
        </Button>
        <Dialog open={open} onClose={this.handleModal} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
          <DialogTitle id="form-dialog-title">Add / Edit Paths</DialogTitle>
          <DialogContent>
            <>
              <AddEditEntitlementValues
                values={(currentEntitlementPath && currentEntitlementPath.allowedValues) || []}
                label="Allowed Values"
                isAllowedValues
                onAddValue={this.onAddValue}
                onDeleteValue={this.onDeleteValue}
              />
              <AddEditEntitlementValues
                values={(currentEntitlementPath && currentEntitlementPath.excludedValues) || []}
                label="Excluded Values"
                isAllowedValues={false}
                onAddValue={this.onAddValue}
                onDeleteValue={this.onDeleteValue}
              />
            </>
          </DialogContent>
          {this.renderDialogActions()}
        </Dialog>
      </>
    );
  }
}

const EntitlementPathModal: React.ComponentClass<IEntitlementPathModalProps, IEntitlementPathModalState> = compose<
  HOCProps,
  IEntitlementPathModalProps
>(
  withTranslation('common'),
  withSnackbar,
)(EntitlementPathModalView);

export default EntitlementPathModal;
