/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { gql } from '@apollo/client';

export const VERIFY_RESET_PASSWORD_TOKEN = gql`
  query verifyResetPasswordToken($hashToken: String!) {
    verifyResetPasswordToken {
      id
    }
  }
`;

export const LOGOUT_USER = gql`
  mutation logout($token: String!) {
    logout(token: $token)
  }
`;
