/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import { ApolloClient } from '@apollo/client';
import { paramCase } from 'change-case';
import { ObjectId } from 'bson';
import { IReport } from '@vamstar/fox-api-common/esm/reports';

import { REPORT_BY_ID } from './queries';
import { REPORT_DATA } from './constants';

export const getSlug = (title: string) => {
  return paramCase(title.replace(/[^\w\s]/gi, ''));
};

const getReportById = async (client: ApolloClient<any>, id: string | ObjectId) => {
  const response = await client.query({
    query: REPORT_BY_ID,
    variables: {
      id,
    },
  });

  return response.data.report;
};

export const fetchData = async (client: ApolloClient<any>, _id?: string): Promise<IReport> => {
  let reportData = REPORT_DATA;
  if (_id) {
    reportData = await getReportById(client, _id);
  }

  return reportData;
};
