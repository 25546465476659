/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React, { PropsWithChildren } from 'react';
import isEqual from 'lodash/isEqual';
import { XGridTable } from '@vamstar/fox-frontend-common';
import { IXGridTableProps } from '@vamstar/fox-frontend-common/dist/main/components/x-grid/interfaces';

interface ICommonXGridWrapperProps<T> extends IXGridTableProps<T> {}

const genericMemo: <T>(
  component: T,
  propsAreEqual?: (
    prevProps: Readonly<PropsWithChildren<ICommonXGridWrapperProps<T>>>,
    nextProps: Readonly<PropsWithChildren<ICommonXGridWrapperProps<T>>>,
  ) => boolean,
) => T = React.memo;

const CommonXGridWrapperComponent = <T,>(props: ICommonXGridWrapperProps<T>) => {
  return <XGridTable<T> {...props} disableColumnFilter disableColumnReorder disableColumnSelector disableColumnMenu />;
};

export const CommonXGridWrapper = genericMemo(
  CommonXGridWrapperComponent,
  (prev, next) => isEqual(prev.rows, next.rows) && isEqual(prev.loading, next.loading),
);
