/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { gql } from '@apollo/client';

import { EntitlementTemplateFragment } from '../entitlement-template/fragments';

export const LICENCE_TEMPLATE = gql`
  query licencetemplates($data: LicenceTemplateListInput!) {
    licencetemplates(data: $data) {
      items {
        _id
        name
        rights {
          ...EntitlementTemplateFields
        }
        basePrice {
          currency
          value
        }
        pricePerSeat {
          currency
          value
        }
        seatsIncluded
        numberOfSeats
        status
      }
      total
      hasMore
    }
  }
  ${EntitlementTemplateFragment.entitlementTemplateFields}
`;

export const LICENCE_TEMPLATE_BY_ID = gql`
  query licencetemplate($id: String!) {
    licencetemplate(id: $id) {
      _id
      name
      rights {
        ...EntitlementTemplateFields
      }
      basePrice {
        currency
        value
      }
      pricePerSeat {
        currency
        value
      }
      seatsIncluded
      numberOfSeats
      status
    }
  }
  ${EntitlementTemplateFragment.entitlementTemplateFields}
`;
