/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import * as React from 'react';
import { Button, Alert } from '@mui/material';
import { LicenceType } from '@vamstar/fox-api-common/esm/licence/types';
import { useHistory } from 'react-router-dom';

interface ILicenceAlertProps {
  licenceId?: string;
  label: string;
}

export const LicenceAlert = (props: ILicenceAlertProps) => {
  const { licenceId, label } = props;
  const history = useHistory();

  const goToLicence = () => {
    if (licenceId) {
      history.push({ pathname: `/licence/${LicenceType.ORGANISATION}/${licenceId}` });
    } else {
      history.push({ pathname: `/licence` });
    }
  };

  return (
    <Alert
      icon={false}
      severity="info"
      action={
        <Button size="small" onClick={() => goToLicence()}>
          {licenceId ? 'VIEW' : 'ADD'}
        </Button>
      }
    >
      {label}
    </Alert>
  );
};
