/**
 * Copyright © 2018 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

export const DEFAULT_TAKE = 100;

export const MIN_SEARCH_STRING_LENGTH = 1;
