/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

/* TODO
- These methods are exported here to support legacy components.
- Will be removed as a part of code refactoring.
- Import statements in other components should be synced up according to the latest frontend-common
*/
export const hasError = (key: string, errors?: { [index: string]: string[] }): boolean => {
  return !!(errors && errors[key]);
};

export const getErrorMessage = (key: string, errors?: { [index: string]: string[] }): string | undefined => {
  return errors && errors[key] && errors[key][0];
};
