/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import React from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { DEFAULT_PAGE_SIZE, IApolloClient, XGridTable } from '@vamstar/fox-frontend-common';
import { ProviderContext, withSnackbar } from 'notistack';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { IElasticSearchFacetsResponse } from '@vamstar/fox-api-common/esm/common/types';
import { log } from '@vamstar/fox-node-logger';
import { Box } from '@mui/system';
import { Typography, Alert, Button } from '@mui/material';
import { IOrganisation } from '@vamstar/fox-api-common/esm/organisation/types';

import { OrganisationFormWrapper } from './OrganisationFormWrapper';
import { OrganisationTab } from './constants';
import { organisationMetricsColumns } from './columns';
import { GET_ORGANISATION_METRICS } from '../queries';
import { enrichOrganisationMetrics, getOrganisationById } from '../utils';
import { IOrganisationMetric, IOrganisationTotalCounts } from '../interfaces';

interface IOrganisationMetricsViewState {
  keywordsAggregation: IElasticSearchFacetsResponse;
  openTendersAggregation: IElasticSearchFacetsResponse;
  dashboardAggregation: IElasticSearchFacetsResponse;
  metrics: IOrganisationMetric[];
  loading: boolean;
  totalCounts: IOrganisationTotalCounts;
  currentPage: number;
  pageSize: number;
  organisation: Partial<IOrganisation>;
}

type HOCProps = WithApolloClient<IApolloClient> &
  ProviderContext &
  RouteComponentProps<{ id: string }> &
  WithTranslation;

class OrganisationMetricsView extends React.Component<HOCProps, IOrganisationMetricsViewState> {
  state: Readonly<IOrganisationMetricsViewState> = {
    keywordsAggregation: {},
    openTendersAggregation: {},
    dashboardAggregation: {},
    metrics: [],
    loading: false,
    totalCounts: {
      dashboardTendersCount: 0,
      openTendersCount: 0,
      totalTendersCount: 0,
    },
    currentPage: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    organisation: {},
  };

  async componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    this.setState({ loading: true });
    if (match.params.id) {
      await this.getOrganisationById(id);
    }
    await this.fetchAggregations();
    this.enrichAggregations();
    if (match.params.id) {
      await this.getOrganisationById(id);
    }
  }

  getOrganisationById = async (id: string) => {
    const { client } = this.props;
    const response = await getOrganisationById(client, id);
    this.setState({ organisation: response });
  };

  enrichAggregations = () => {
    const { keywordsAggregation, dashboardAggregation, openTendersAggregation } = this.state;
    const organisationMetricsMap: Map<string, IOrganisationMetric> = new Map<string, IOrganisationMetric>();
    enrichOrganisationMetrics(organisationMetricsMap, keywordsAggregation, 'totalCount');
    enrichOrganisationMetrics(organisationMetricsMap, openTendersAggregation, 'openTendersCount');
    enrichOrganisationMetrics(organisationMetricsMap, dashboardAggregation, 'dashboardCount');
    this.setState({ metrics: Array.from(organisationMetricsMap.values()), loading: false });
  };

  onPageSizeChange = (pageSize: number) => {
    this.setState({ pageSize, currentPage: 1 });
  };

  onPageChange = (page: number) => {
    this.setState({ currentPage: page });
  };

  exportExcel = () => {
    const { metrics } = this.state;
    import('xlsx').then((XLSX) => {
      const worksheet = XLSX.utils.json_to_sheet(metrics);

      const workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Organisation Metrics');

      XLSX.writeFile(workbook, `vamstar_${process.env.REACT_APP_STAGE}_admin.xlsx`);
    });
  };

  async fetchAggregations() {
    try {
      const { client, match } = this.props;
      const { data } = await client.query({
        query: GET_ORGANISATION_METRICS,
        variables: {
          organisationId: match.params.id,
        },
        fetchPolicy: 'network-only',
      });
      const { getLicencedTagsCount, getOpenTendersCount, getDashboardKeywordsCount } = data;
      this.setState({
        keywordsAggregation: getLicencedTagsCount.aggregations,
        openTendersAggregation: getOpenTendersCount.aggregations,
        dashboardAggregation: getDashboardKeywordsCount.aggregations,
        totalCounts: {
          totalTendersCount: getLicencedTagsCount.total,
          openTendersCount: getOpenTendersCount.total,
          dashboardTendersCount: getDashboardKeywordsCount.total,
        },
      });
    } catch (err) {
      log.error(`Error while getching aggregations ${err}`);
    }
  }

  render(): React.ReactNode {
    const { match } = this.props;
    const { metrics, loading, totalCounts, pageSize, currentPage, organisation } = this.state;

    return (
      <OrganisationFormWrapper
        organisationId={match.params.id}
        tab={OrganisationTab.METRICS}
        hasLicence={!!organisation.licence}
      >
        <Alert icon={false} severity="info">
          Note: The counts shown here are based on the organisation entitlements configured in the licence.
        </Alert>
        <Box marginTop={2}>
          <Typography>Dashboard tenders available: {totalCounts.dashboardTendersCount}</Typography>
          <Typography>Open tenders available: {totalCounts.openTendersCount}</Typography>
          <Typography>Total tenders available: {totalCounts.totalTendersCount}</Typography>
        </Box>
        <Box marginTop={2}>
          <Button color="primary" onClick={() => this.exportExcel()}>
            Export Stats
          </Button>
        </Box>
        <Box sx={{ display: 'block' }}>
          <XGridTable<IOrganisationMetric>
            modelId="id"
            loading={loading}
            columns={organisationMetricsColumns}
            rows={metrics.slice(pageSize * (currentPage - 1), pageSize * currentPage)}
            count={metrics.length}
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={this.onPageChange}
            onPageSizeChange={this.onPageSizeChange}
          />
        </Box>
      </OrganisationFormWrapper>
    );
  }
}

export const OrganisationMetrics: React.ComponentClass<any, IOrganisationMetricsViewState> = compose<HOCProps, any>(
  withApollo,
  withSnackbar,
  withRouter,
  withTranslation('common'),
)(OrganisationMetricsView);
