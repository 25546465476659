/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import * as React from 'react';
import { Box, Switch, Tooltip } from '@mui/material';
import { GridApi, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { IThirdPartyAppIntegration } from '@vamstar/fox-api-common/esm/organisation/types/third-party-app-integration';
import { IntegrationApp, IntegrationStatus } from '@vamstar/fox-api-common/esm/organisation/types';
import { compose } from 'recompose';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { ProviderContext, withSnackbar } from 'notistack';
import { IApolloClient } from '@vamstar/fox-frontend-common';
import { log } from '@vamstar/fox-node-logger';

import { UPDATE_INTEGRATION } from '../mutations';
import { getSalesforceIntegrationObject } from '../utils';

const isSalesforceIntegrationEnabled = (integrations: IThirdPartyAppIntegration[]) => {
  return integrations.some(
    (integration: IThirdPartyAppIntegration) =>
      integration.status === IntegrationStatus.ENABLED && integration.app === IntegrationApp.SALESFORCE,
  );
};

interface IEnableSalesforceViewProps {
  params: GridRenderCellParams;
}
type HOCProps = WithApolloClient<IApolloClient> & ProviderContext & IEnableSalesforceViewProps;
interface IEnableSalesforceViewState {
  isSalesforceEnabled: boolean;
}
class EnableSalesforceView extends React.Component<HOCProps, IEnableSalesforceViewState> {
  state: IEnableSalesforceViewState = {
    isSalesforceEnabled: isSalesforceIntegrationEnabled(this.props.params.row.integrations),
  };

  updateSalesforceState = () => {
    const { isSalesforceEnabled } = this.state;
    this.setState({ isSalesforceEnabled: !isSalesforceEnabled });
  };

  updateIntegration = async (integration: IThirdPartyAppIntegration) => {
    const { client, enqueueSnackbar, params } = this.props;
    const api = params.api as GridApi;
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_INTEGRATION,
        variables: {
          orgId: params.row.id,
          integration,
        },
      });
      const { updateIntegrations } = data;
      api.updateRows([{ id: updateIntegrations._id, integrations: updateIntegrations.integrations }]);
      if (integration.status === IntegrationStatus.ENABLED) {
        enqueueSnackbar('Salesforce enabled successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Salesforce disabled successfully', { variant: 'success' });
      }
      this.updateSalesforceState();
    } catch (error) {
      log.error(JSON.stringify(error));
    }
  };

  handleClick = () => {
    const { isSalesforceEnabled } = this.state;
    if (isSalesforceEnabled) {
      this.updateIntegration(getSalesforceIntegrationObject(IntegrationStatus.DISABLED));
    } else {
      this.updateIntegration(getSalesforceIntegrationObject(IntegrationStatus.ENABLED));
    }
  };

  render() {
    const { isSalesforceEnabled } = this.state;

    return (
      <Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Tooltip
            title={
              isSalesforceEnabled ? 'Click to disable salesforce integration' : 'Click to enable salesforce integration'
            }
          >
            <Switch checked={isSalesforceEnabled} onClick={this.handleClick} />
          </Tooltip>
        </Box>
      </Box>
    );
  }
}

export const EnableSalesforce: React.ComponentClass<IEnableSalesforceViewProps, IEnableSalesforceViewState> = compose<
  HOCProps,
  IEnableSalesforceViewProps
>(
  withApollo,
  withSnackbar,
)(EnableSalesforceView);
