import { Login } from './Login';
import { PasswordReset } from './PasswordReset';
import { ICustomRouteProps } from '../../navigation/interfaces';

export const authRoutes: ICustomRouteProps[] = [
  {
    path: '/login',
    component: Login,
    exact: true,
    title: 'Login',
  },
  {
    path: '/reset-password',
    component: PasswordReset,
    exact: true,
    title: 'Reset Password',
  },
  {
    path: '/reset-password/:resetToken',
    component: PasswordReset,
    title: 'Reset Token',
  },
];
