/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { IReport, ReportStatus, ReportType } from '@vamstar/fox-api-common/esm/reports';

export const REPORT_DATA: IReport = {
  _id: '',
  title: '',
  subTitle: '',
  description: '',
  type: ReportType.FREE,
  blocks: [],
  status: ReportStatus.PRE_RELEASE,
  s3File: {
    originalUrl: '',
    key: '',
    bucket: '',
  },
  thumbnail: {
    originalUrl: '',
    key: '',
    bucket: '',
  },
};
