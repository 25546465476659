/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { IEntitlementTemplate, IGeography } from '@vamstar/fox-api-common/esm/entitlement-template/types';
import React from 'react';
import { VamProduct } from '@vamstar/fox-api-common/esm/vam-product/types';
import { IReport } from '@vamstar/fox-api-common/esm/reports';

import EntitlementTemplate from './component/EntitlementTemplate';

interface EntitlementTemplateWrapperWithModalProps {
  products: VamProduct[];
  reports: IReport[];
  countries: IGeography[];
  entitlementtemplateData: Partial<IEntitlementTemplate>;
  onEntitlementTemplateSave: () => void;
  onEntitlementTemplateUpdate: () => void;
  setEntitlementType: (prop: string, value: any) => void;
  onChange: (prop: string, value: any) => void;
  handleModal: () => void;
  isModalVisible: boolean;
  entitlementTemplateIndex?: number;
  allowEditAlways?: boolean;
}

class EntitlementTemplateWithModal extends React.Component<EntitlementTemplateWrapperWithModalProps> {
  isSave = () => {
    const { entitlementTemplateIndex } = this.props;
    return typeof entitlementTemplateIndex === 'undefined';
  };

  renderDialogActions = () => {
    const {
      onEntitlementTemplateSave,
      onEntitlementTemplateUpdate,
      handleModal,
      entitlementtemplateData: { name },
    } = this.props;
    return (
      <DialogActions>
        <Button onClick={handleModal} color="primary">
          Cancel
        </Button>
        <Button
          onClick={this.isSave() ? onEntitlementTemplateSave : onEntitlementTemplateUpdate}
          color="primary"
          disabled={!name || name === ''}
        >
          {this.isSave() ? 'Save' : 'Update'}
        </Button>
      </DialogActions>
    );
  };

  render() {
    const {
      products,
      reports,
      countries,
      entitlementtemplateData,
      setEntitlementType,
      onChange,
      isModalVisible,
      handleModal,
    } = this.props;
    return (
      <>
        <Dialog open={isModalVisible} onClose={handleModal} fullWidth maxWidth="md">
          <DialogTitle>Add / Edit Entitlement</DialogTitle>
          <DialogContent>
            <Grid container justifyContent="center">
              <Grid container item direction="column" justifyContent="center" xs={8} spacing={5}>
                <EntitlementTemplate
                  entitlementtemplateData={entitlementtemplateData}
                  products={products}
                  reports={reports}
                  countries={countries}
                  setEntitlementType={setEntitlementType}
                  onChange={onChange}
                  allowEditAlways
                />
              </Grid>
            </Grid>
          </DialogContent>
          {this.renderDialogActions()}
        </Dialog>
      </>
    );
  }
}

export default EntitlementTemplateWithModal;
