/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import * as React from 'react';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { IOrganisation, OrganisationStatus, OrganisationType } from '@vamstar/fox-api-common/esm/organisation/types';
import { IApolloClient, FormTemplateFullWidth } from '@vamstar/fox-frontend-common';
import { withSnackbar, ProviderContext } from 'notistack';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Box, TextField } from '@mui/material';
import { log } from '@vamstar/fox-node-logger';
import { WithTranslation, withTranslation } from 'react-i18next';
import { validate } from 'validate.js';
import { IWorkDocsProperties } from '@vamstar/fox-api-common/esm/organisation/types/work-docs';

import { SingleSelectField } from '../../../common/components/SelectField';
import { CREATE_ORGANISATION, UPDATE_ORGANISATION } from '../mutations';
import { ORGANISATION_FORM_CONSTRAINTS } from './constraints';
import { OrganisationFormWrapper } from './OrganisationFormWrapper';
import { OrganisationTab } from './constants';
import { getOrganisationById } from '../utils';
import { getErrorMessage, hasError } from '../../../common/utils/errorHandler';
import { LicenceAlert } from './LicenceAlert';
import { removeProp } from '../../../util/object';

interface IOrganisationState {
  isLoading: boolean;
  organisation: Partial<IOrganisation>;
}

type HOCProps = WithApolloClient<IApolloClient> &
  ProviderContext &
  RouteComponentProps<{ id: string }> &
  WithTranslation;

class OrganisationFormView extends React.Component<HOCProps, IOrganisationState> {
  state: IOrganisationState = {
    isLoading: true,
    organisation: {},
  };

  async componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (match.params.id) {
      await this.getOrganisationById(id);
    }
    this.setState({ isLoading: false });
  }

  getOrganisationById = async (id: string) => {
    const { client } = this.props;
    const response = await getOrganisationById(client, id);
    this.setState({ organisation: response });
  };

  onSubmit = async () => {
    const { organisation } = this.state;
    if (organisation._id) {
      await this.onUpdate();
    } else {
      await this.onCreate();
    }
  };

  onCreate = async () => {
    const { client, enqueueSnackbar, t } = this.props;
    const { organisation } = this.state;
    const { name, emailDomain, status, description, workDocsProperties, type } = organisation;
    try {
      const response = await client.mutate({
        mutation: CREATE_ORGANISATION,
        variables: {
          data: { name, emailDomain, status, description, workDocsProperties, type },
        },
      });
      const { createOrganisation } = response.data;
      enqueueSnackbar(t('recordCreated'), { variant: 'success' });
      this.setState({ organisation: createOrganisation });
      this.props.history.push({
        pathname: `/organisation/${createOrganisation._id}/details`,
      });
    } catch (error) {
      log.error(JSON.stringify(error));
    }
  };

  onUpdate = async () => {
    const { client, enqueueSnackbar, t } = this.props;
    const { organisation } = this.state;
    const { name, status, description, workDocsProperties, type } = organisation;
    const workDocsProps = removeProp(workDocsProperties, '__typename');

    try {
      const response = await client.mutate({
        mutation: UPDATE_ORGANISATION,
        variables: {
          data: {
            filter: { id: organisation._id },
            doc: { name, status, description, workDocsProperties: workDocsProps, type },
          },
        },
      });
      const { updateOrganisation } = response.data;
      this.setState({ organisation: updateOrganisation });
      enqueueSnackbar(t('recordUpdated'), { variant: 'success' });
      this.props.history.push({
        pathname: `/organisation/${updateOrganisation._id}/details`,
      });
    } catch (error) {
      log.error(JSON.stringify(error));
    }
  };

  getFormErrors = () => {
    const { organisation } = this.state;
    return validate({ ...organisation }, ORGANISATION_FORM_CONSTRAINTS);
  };

  setWorkDocsProperties = (property: keyof IWorkDocsProperties, value: string) => {
    const { organisation } = this.state;
    const newOrganisation = { ...organisation };

    if (!newOrganisation.workDocsProperties) {
      newOrganisation.workDocsProperties = { rootFolderId: '', workDocsOrganisationId: '' };
    }

    const { workDocsProperties } = newOrganisation;
    this.setState({
      organisation: { ...organisation, workDocsProperties: { ...workDocsProperties, [property]: value } },
    });
  };

  renderWorkDocTextFields = () => {
    const { organisation } = this.state;
    const errors = this.getFormErrors();
    let { workDocsProperties } = organisation;

    if (!workDocsProperties) {
      workDocsProperties = { rootFolderId: '', workDocsOrganisationId: '' };
    }

    return (
      <>
        <TextField
          fullWidth
          margin="normal"
          variant="filled"
          value={workDocsProperties.workDocsOrganisationId}
          error={hasError('workDocsOrganisationId', errors)}
          label="Work Docs Organisation"
          onChange={(event) => this.setWorkDocsProperties('workDocsOrganisationId', event.target.value)}
          helperText={getErrorMessage('workDocsOrganisationId', errors)}
          placeholder="Work docs organisation id"
        />
        <TextField
          fullWidth
          margin="normal"
          variant="filled"
          value={workDocsProperties.rootFolderId}
          error={hasError('rootFolderId', errors)}
          label="Admin root folder"
          onChange={(event) => this.setWorkDocsProperties('rootFolderId', event.target.value)}
          helperText={getErrorMessage('rootFolderId', errors)}
          placeholder="Root folder id"
        />
      </>
    );
  };

  renderOrganisationSlug = () => {
    const { organisation } = this.state;
    const { match } = this.props;

    if (match.params.id) {
      return (
        <TextField
          fullWidth
          margin="normal"
          variant="filled"
          value={organisation.slug}
          label="Organisation slug"
          disabled
        />
      );
    }
    return <></>;
  };

  renderOrganisationType = () => {
    const errors = this.getFormErrors();
    const { organisation } = this.state;
    return (
      <SingleSelectField
        data={Object.keys(OrganisationType).map((type) => type)}
        onChange={(newType: string) => {
          this.setState({ organisation: { ...organisation, type: newType as OrganisationType } });
        }}
        label="Organisation Type"
        defaultValue={organisation.type}
        hasError={hasError('type', errors)}
        helperText={getErrorMessage('type', errors)}
        required
      />
    );
  };

  renderTextFields = () => {
    const { organisation } = this.state;
    const errors = this.getFormErrors();
    const { match } = this.props;
    return (
      <>
        <TextField
          fullWidth
          margin="normal"
          variant="filled"
          value={organisation.name || ''}
          error={hasError('name', errors)}
          label="Name"
          onChange={(event) => this.setState({ organisation: { ...organisation, name: event.target.value } })}
          required
          helperText={getErrorMessage('name', errors)}
        />
        <TextField
          fullWidth
          margin="normal"
          variant="filled"
          value={organisation.description || ''}
          error={hasError('description', errors)}
          label="Description"
          onChange={(event) => this.setState({ organisation: { ...organisation, description: event.target.value } })}
          required
          helperText={getErrorMessage('description', errors)}
        />
        <TextField
          fullWidth
          margin="normal"
          variant="filled"
          value={organisation.emailDomain || ''}
          error={hasError('emailDomain', errors)}
          label="Email Domain"
          onChange={(event) => this.setState({ organisation: { ...organisation, emailDomain: event.target.value } })}
          required
          helperText={getErrorMessage('emailDomain', errors)}
          disabled={!!match.params.id}
          placeholder="Example - xyz.com, abc.co"
        />
        {this.renderOrganisationSlug()}
        {this.renderWorkDocTextFields()}
        {this.renderOrganisationType()}
      </>
    );
  };

  isFormDisabled = () => {
    const { organisation } = this.state;
    const { description, emailDomain, name, status } = organisation;
    return !description || !emailDomain || !name || !status;
  };

  showLicenceBar = () => {
    const { organisation } = this.state;
    const { licence } = organisation;

    if (licence) {
      const { _id, name } = licence;
      return <LicenceAlert licenceId={_id!.toString()} label={`${name} licence is attached`} />;
    }

    if (!licence && organisation._id) {
      return <LicenceAlert label="Attach a new licence to this organisation to add new users" />;
    }
    return <></>;
  };

  render() {
    const { isLoading, organisation } = this.state;
    const { match } = this.props;
    const errors = this.getFormErrors();
    return (
      <OrganisationFormWrapper
        organisationId={match.params.id}
        hasLicence={!!organisation.licence}
        tab={OrganisationTab.DETAILS}
      >
        <Box marginY={2}>{this.showLicenceBar()}</Box>
        <FormTemplateFullWidth
          formLabel="Organisation"
          isEdit={!!match.params.id}
          isLoading={isLoading}
          onSubmit={this.onSubmit}
          isFormDisabled={!!errors || this.isFormDisabled()}
        >
          {this.renderTextFields()}
          <SingleSelectField
            data={[OrganisationStatus.ACTIVE, OrganisationStatus.INACTIVE]}
            label="Status"
            onChange={(status) =>
              this.setState({ organisation: { ...organisation, status: status as OrganisationStatus } })
            }
            defaultValue={organisation.status}
            required
            hasError={hasError('status', errors)}
            helperText={getErrorMessage('status', errors)}
          />
        </FormTemplateFullWidth>
      </OrganisationFormWrapper>
    );
  }
}

export const OrganisationForm: React.ComponentClass<any, IOrganisationState> = compose<HOCProps, any>(
  withApollo,
  withSnackbar,
  withRouter,
  withTranslation('common'),
)(OrganisationFormView);
