/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */
import * as React from 'react';
import { IEmUser } from '@vamstar/fox-api-common/esm/user/types';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import { IApolloClient } from '@vamstar/fox-frontend-common';
import { withSnackbar, ProviderContext } from 'notistack';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Avatar, Box, Button, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import SupervisedUserCircleRounded from '@mui/icons-material/SupervisedUserCircleRounded';
import { IOrganisation } from '@vamstar/fox-api-common/esm/organisation/types';

import { ORGANISATION_USERS } from '../queries';
import { OrganisationFormWrapper } from './OrganisationFormWrapper';
import { OrganisationTab } from './constants';
import { OrganisationAddUserFormModal } from './OrganisationAddUserModal';
import { getOrganisationById } from '../utils';

interface IOrganisationUsersFormState {
  isLoading: boolean;
  users: IEmUser[];
  open: boolean;
  organisation: Partial<IOrganisation>;
}

type HOCProps = WithApolloClient<IApolloClient> & ProviderContext & RouteComponentProps<{ id: string }>;

class OrganisationUsersFormView extends React.Component<HOCProps, IOrganisationUsersFormState> {
  state: IOrganisationUsersFormState = {
    isLoading: true,
    users: [],
    open: false,
    organisation: {},
  };

  async componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (match.params.id) {
      await this.getOrganisationById(id);
      await this.getOrganisationUsers(id);
      await this.getOrganisationById(id);
    }
    this.setState({ isLoading: false });
  }

  getOrganisationById = async (id: string) => {
    const { client } = this.props;
    const response = await getOrganisationById(client, id);
    this.setState({ organisation: response });
  };

  getOrganisationUsers = async (id: string) => {
    const { client } = this.props;
    const response = await client.query({
      query: ORGANISATION_USERS,
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    });
    const { getAllUsersInOrganisation } = response.data;
    this.setState({ users: getAllUsersInOrganisation });
  };

  addNewUser = (user: IEmUser) => {
    this.setState((prevState) => ({
      ...prevState,
      users: [...prevState.users, user],
    }));
  };

  handleModal = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  // TODO: Add skeleton loading later
  render() {
    const { match } = this.props;
    const { users, isLoading, open, organisation } = this.state;
    return (
      <OrganisationFormWrapper
        organisationId={match.params.id}
        tab={OrganisationTab.USERS}
        hasLicence={!!organisation.licence}
      >
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">All Users</Typography>
          <Button variant="contained" color="primary" size="medium" onClick={this.handleModal}>
            Add User
          </Button>
          <OrganisationAddUserFormModal
            organisationId={match.params.id}
            selectedUsers={users}
            open={open}
            handleClose={this.handleModal}
            addNewUser={this.addNewUser}
          />
        </Grid>
        {!isLoading && (
          <Box marginY={3}>
            <List dense={false}>
              {users.map((user) => {
                return (
                  <ListItem key={user.email}>
                    <ListItemAvatar>
                      <Avatar>
                        <SupervisedUserCircleRounded />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`${user.firstName} ${user.lastName}`} secondary={user.email} />
                  </ListItem>
                );
              })}
            </List>
          </Box>
        )}
      </OrganisationFormWrapper>
    );
  }
}

export const OrganisationUsersForm: React.ComponentClass<any, IOrganisationUsersFormState> = compose<HOCProps, any>(
  withApollo,
  withSnackbar,
  withRouter,
)(OrganisationUsersFormView);
