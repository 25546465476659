/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { routes } from './routes';
import { PrivateRoute } from '../common/components/PrivateRoute';
import { ICustomRouteProps } from './interfaces';
import { DashboardNavigator } from './DashboardNavigator';

export const RootNavigator = () => (
  <Switch>
    {routes.map((routeConfig: ICustomRouteProps) => {
      if (routeConfig.isPrivate) {
        return <PrivateRoute exact path={routeConfig.path} key={routeConfig.title} component={routeConfig.component} />;
      }
      return <Route key={routeConfig.title} {...routeConfig} />;
    })}
    <DashboardNavigator />
  </Switch>
);
