/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React from 'react';
import { Card, CardContent, CardActions, LinearProgress, Typography, useTheme } from '@mui/material';
import { LineChart } from '@vamstar/fox-frontend-common';

import { useSystemActivityStyles, GRAPH_HEIGHT } from '../styles';
import { ISystemActivityData } from '../interface';

interface IUserLineChart {
  data: ISystemActivityData[];
  isLoading: boolean;
  graphTitle: string;
}

export const ActivityChart = ({ data, isLoading, graphTitle }: IUserLineChart) => {
  const classes = useSystemActivityStyles();
  const theme = useTheme();
  const lineChartData = [...data];

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" component="h5">
            {graphTitle}&nbsp;
          </Typography>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <div style={{ minWidth: '100%', height: theme.spacing(GRAPH_HEIGHT) }}>
              <LineChart chartData={lineChartData} />
            </div>
          )}
        </CardContent>
        <CardActions />
      </Card>
    </>
  );
};
