/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { gql } from '@apollo/client';

import { EntitlementTemplateFragment } from './fragments';

export const CREATE_ENTITLEMENT = gql`
  mutation createEntitlementtemplate($data: EntitlementTemplateCreateInput!) {
    createEntitlementtemplate(data: $data) {
      ...EntitlementTemplateFields
    }
  }
  ${EntitlementTemplateFragment.entitlementTemplateFields}
`;

export const UPDATE_ENTITLEMENT = gql`
  mutation updateEntitlementtemplate($data: EntitlementTemplateUpdateInput!) {
    updateEntitlementtemplate(data: $data) {
      ...EntitlementTemplateFields
    }
  }
  ${EntitlementTemplateFragment.entitlementTemplateFields}
`;
