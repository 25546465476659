/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import { TokenType } from '@vamstar/fox-api-common/esm/claim-supplier-profile/types';

import { MAIL_REGEX } from './constants';
import { InviteSuppliersFormViewState } from './interfaces';

export const isEmailValid = (email: string | undefined) => {
  if (email === undefined) return true;
  return MAIL_REGEX.test(email);
};

const getEmailHelperText = (email: string | undefined) => {
  let helperText = '';
  if (email !== undefined && !isEmailValid(email)) {
    helperText = 'Invalid email address';
  }
  return helperText;
};

const isFieldValueValid = (value: string | undefined) => {
  if (value === undefined || value) return false;
  return true;
};

const getFieldHelperText = (value: string | undefined) => {
  if (value === undefined || value) return '';
  return 'Please fill this field';
};

export const getValidationProps = (fieldName?: string, fieldValue?: string) => {
  let error = false;
  let helperText = '';
  if (fieldName === 'email') {
    error = !isEmailValid(fieldValue);
    helperText = getEmailHelperText(fieldValue);
  } else {
    error = isFieldValueValid(fieldValue);
    helperText = getFieldHelperText(fieldValue);
  }

  return {
    error,
    helperText,
  };
};

export const isFormDisabled = (state: InviteSuppliersFormViewState) => {
  const booleanKeys = Object.values(state.supplierValidity);

  return booleanKeys.some((e) => !e);
};

export const getInvitationStatus = (rowData: any) => {
  if (rowData.isActive) {
    if (rowData.type === TokenType.INVITE) return 'Invited';
    return 'Pending password creation';
  }
  return 'Profile claimed';
};
