/**
 * Copyright © 2019 - Present, Vamstar Limited
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or
 * without modification, are not permitted.
 */

import React, { Component, SyntheticEvent } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';

interface IUploadFilesProps {
  onChange: (value: Record<string, any>) => void;
  title: string;
}

interface IUploadFilesState {
  file?: FileList;
}

type HOCProps = WithTranslation & IUploadFilesProps;

class UploadFilesView extends Component<HOCProps, IUploadFilesState> {
  public state: IUploadFilesState = {
    file: undefined,
  };

  public uploadFile = (event: SyntheticEvent) => {
    const { files } = event.target as HTMLInputElement;
    const { onChange } = this.props;
    if (files && files.length > 0) {
      this.setState({ file: files });
      onChange(files);
    }
  };

  renderFileInfo = () => {
    const { file } = this.state;
    return (
      <TableRow>
        <TableCell component="th" scope="row" align="center">
          {file && file.length}
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {file &&
            Array.from(file)
              .map((item) => item.name)
              .join(', ')}
        </TableCell>
      </TableRow>
    );
  };

  public render() {
    const { title } = this.props;
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">No. of Files</TableCell>
              <TableCell align="center">File Names</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderFileInfo()}
            <TableRow>
              <TableCell component="th" scope="row" align="right" colSpan={3}>
                <Button variant="outlined" component="label" color="primary">
                  <PublishIcon />
                  Add {title}
                  <input
                    type="file"
                    required
                    style={{ display: 'none' }}
                    multiple
                    onChange={(event) => this.uploadFile(event)}
                  />
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export const UploadFiles: React.ComponentClass<IUploadFilesProps> = compose<HOCProps, IUploadFilesProps>(
  withTranslation(),
)(UploadFilesView);
